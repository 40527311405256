import { gql } from '@apollo/client';

export const GET_PROFESSIONALREGISTRATIONS = gql`
  {
    professionalRegistrations {
      name
      professionalRegistrationId
    }
  }
`;

export const GET_PROFESSIONS = gql`
  {
    professions {
      name
      professionId
    }
  }
`;

export const GET_REGIONS = gql`
  {
    regions {
        regionId,
        name
    }
  }
`;
export const GET_EMDR_TRAINING_LEVELS = gql`
  {
    emdrTrainingLevels {
        emdrTrainingLevelId,
        name
    }
  }
`;
export const GET_COUNTRIES = gql`
  {
    countries {
        countryId
        name
    }
  }
`;
export const GET_MEMBERSHIPTYPES = gql`
  {
    membershipTypes {
        membershipTypeId,
        name,
        description
    }
  }
`;

export const GET_APPLICATION_CONFIG = gql`
  {
    activeAccreditationTypes {
        accreditationTypeId
        description
        name
        price
        requirementsJson
    }
  }
`;

export const GET_ACCREDITATION_TYPES = gql`
  {
    activeAccreditationTypes {
        accreditationTypeId
        description
        name
        price
        requirementsJson
    }
  }
`;

export const GET_ACCREDITATION_APPLICATIONS = gql`
  {
    accreditationApplications {
      accreditationApplicationId
      jsonData
      reviews {
          applicationReviewId
          reviewerRole
          reviewStatus
          updatedDate
          reviewer {
              name
              memberId
          }
          applicant {
              name
          }
      }
      accreditationType {
          accreditationTypeId
          name
          price
          requirementsJson
      }
      applicationStatus
      lockedBy
      updatedDate
      isPaid
    }
  }
`;

export const GET_APPLICATION_REVIEWS = gql`
  query GetAccreditationApplicationReviewSummaries {
    accreditationApplicationReviewSummaries {
      accreditationApplicationId
      accreditationType {
          accreditationTypeId
          name
      }
      applicant {
        name
        memberId
      }
      reviewer {
        memberId
        name
      }
      applicationReviewId
      reviewerRole
      requestedDate
      updatedDate
      reviewStatus
    }
  }
`;

export const GET_APPLICATION_REVIEW = gql`
    query GetAccreditationApplicationReview($reviewId: String!) {
        accreditationApplicationReview(reviewId: $reviewId) {
          accreditationApplication {
            jsonData
            accreditationApplicationId
            memberId
            accreditationType {
              requirementsJson
              name
            }
            applicationStatus
          }
          jsonData
          applicationReviewId
        }
    }
`;
export const GET_COMMUNICATIONPREFERENCES = gql`
  query CommunicationPreferences($memberId:UUID!){
      communicationPreferences(memberId:$memberId){
          memberId,
          name,
          enabled
      }
    }
`;


export const CHECK_USERNAME = gql`
  query CheckUsername($username: String!) {
  checkUsername(username: $username)
}
`

export const SEARCH_MEMBERS = gql`
  query GetMembers($searchTerm: String!) {
    members(nameFilter: $searchTerm) {
      name,
      memberId
    }
  }
`;

export const SEARCH_SUPERVISORS = gql`
  query GetSupervisors($searchTerm: String!) {
    members(nameFilter: $searchTerm, consultantsOnly: true) {
      name,
      memberId,
      email
    }
  }
`;