import React, {useEffect} from "react";
import IEvaluationFormStep from "interfaces/IEvaluationFormStep";
import {Button, Col, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import {IPartData, IPartDData} from "interfaces/IApplicationReviewForm";
import {LoadingSpinner} from "../../../LoadingSpinner";

const PartD: React.FC<IEvaluationFormStep> = ({models, formData, formRef, handleFeedback, handleInputChange, readOnly = false}) => {

  const applicantName = models.applicationReview.applicant.name;
  
  const [partValue, setPartValue] = React.useState<IPartData<IPartDData>>(formData.partD);

  useEffect(() => {
    handleInputChange("partD", partValue)
  }, [partValue]);

  const handleChange = (name: string, value: string | number) => {
    setPartValue(prevState => ({
      ...prevState,
      value: {
        ...prevState.value,
        [name]: value
      }
    }));
  }

  const handleChangeComplete = (value: boolean) => {
    if (value) {
      const result = formRef.current.checkValidity();
      if (!result) {
        handleFeedback("danger", "Cannot mark as complete whilst form has errors");
        return;
      }
    }
    setPartValue(prevState => ({
      ...prevState,
      complete: value
    }));
  }

  useEffect(() => {
    setPartValue(formData.partD || {});
  }, [formData.partD]);

  if (!partValue) {
    return <LoadingSpinner/>
  }

  const renderInput = (name: string, labelText: string) => {
    const inputValue = (partValue?.value && partValue.value[name]);
    return (
      <>
        <dt className={"col-sm-2"}>
          {!readOnly && <Form.Control
            name={name}
            key={name}
            plaintext={readOnly}
            type={"number"}
            readOnly={readOnly || partValue?.complete}
            onChange={(e) => handleChange(name, e.target.value)}
            value={inputValue || ""}
            min={0}
            required
            isValid={inputValue !== undefined && (inputValue as number) >= 0}
            isInvalid={inputValue !== undefined && (inputValue === "" || (inputValue as number) < 0)}
          />}
          {readOnly && inputValue}
        </dt>
        <dd className={"col-sm-10"}>
          {labelText}
        </dd>
      </>);
  }

  return (<Row className={"col-8"}>
    <h2>Part D: Supervision details {partValue?.complete && <i className={"bi bi-check2-circle me-2 fs-4"}/>}</h2>
    <p className={"text-black"}>Tell us more about the supervision, and why you're recommending {applicantName}'s
      accreditation as an EMDR Europe Practitioner.</p>
    <Row className={"text-black fw-bold"}>
      <p>How many hours of supervision have taken place?</p>
    </Row>
    <Row>
      <dl className={"row"}>
    {renderInput("hoursIndividual", "hours face to face (individual)")}
    {renderInput("hoursGroup", "hours face to face (in a group)")}
    {renderInput("hoursTelephone", "hours by telephone / online meetings")}
    {renderInput("hoursEmail", "hours by email")}
    {renderInput("hoursOther", "hours by other methods")}
      </dl>
    </Row>
    <FormGroup className={"text-black"}>
      <Form.Label>
        <p className={"fw-bold"}>Please specify your reasons for recommending {applicantName}'s accreditation as an EMDR Europe Practitioner:</p>
        <FormControl
          as={"textarea"}
          rows={10}
          plaintext={readOnly}
          className="mt-2"
          key={"reasons"}
          required
          value={partValue?.value?.reasons || ""}
          name={"reasons"}
          readOnly={readOnly || partValue?.complete}
          onChange={(e) => handleChange("reasons", e.target.value)}
          minLength={partValue?.minLength || 1}
          isValid={partValue?.value?.reasons !== undefined && partValue?.value?.reasons.trim().length >= (partValue?.minLength || 10)}
          isInvalid={partValue?.value?.reasons !== undefined && partValue?.value?.reasons.trim().length < (partValue?.minLength || 10)}
        />
      </Form.Label>
    </FormGroup>
    <FormControl name="complete" type={"hidden"} value={partValue?.complete ? "true" : "false"}/>
    {!readOnly &&
      <Button
        className={"mt-3"}
        variant="secondary"
        onClick={() => handleChangeComplete(!partValue?.complete)}>
        Mark part D as {partValue?.complete ? "in" : ""}complete
      </Button>
    }
  </Row>);
}

export default PartD;