import React, { useEffect, useRef } from "react";
import IEvaluationFormStep from "interfaces/IEvaluationFormStep";
import { Button, Form, FormControl, FormGroup, Row } from "react-bootstrap";
import { LoadingSpinner } from "../../../LoadingSpinner";

const SingleInput: React.FC<IEvaluationFormStep> = ({formData, formRef,
                                                      handleFeedback, handleInputChange, requirementsGroup,
                                                      readOnly = false}) => {

  const formPartData = formData[requirementsGroup.name];

  console.log("formPartData", formPartData)

  const [ partComplete, setPartComplete ] = React.useState<boolean>(formPartData?.complete || false);
  const [ partValue, setPartValue] = React.useState<string>(formPartData?.value);
  const [ showValidation, setShowValidation ] = React.useState(false);

  const formControlRef = useRef<any>();

  useEffect(() => {
    if (partValue) {
      handleInputChange(requirementsGroup.name, {
        value: partValue,
        complete: partComplete
      })
    }
  }, [partValue, partComplete]);

  const handleChangeComplete = (value: boolean) => {
    setShowValidation(true);
    if (value) {
      const result = formControlRef.current && formControlRef.current.checkValidity();
      if (!result) {
        handleFeedback("danger", "Cannot mark as complete whilst form has errors");
        return;
      }
    }
    setPartComplete(value);
  }

  useEffect(() => {
    if (formPartData) {
      setPartValue(formPartData.value);
      setPartComplete(formPartData.complete);
    }
  }, [formPartData]);

  if (!formPartData) {
    return <LoadingSpinner/>
  }

  const req = requirementsGroup.requirements[0];
  const { minLength = 1, maxLength } = req;

  const valid = partValue?.length >= minLength && partValue?.length <= (maxLength ?? Number.MAX_SAFE_INTEGER);

  return (<Row className={"col-8"}>
    <h2>{requirementsGroup.pageTitle} {partComplete && <i className={"bi bi-check2-circle me-2 fs-4"}/>}</h2>
    <p className={"text-black"}>{requirementsGroup.pageSubtitle}</p>
    <FormGroup>
      <Form.Label className={"text-black"}>
        <p className={"fw-bold"}>{req.subtitle}</p>
        {
            req.elements?.length && <ul>
            {req.elements.map((e, i) => <li key={i}>{e}</li>)}
          </ul>
        }
        <FormControl
          ref={formControlRef}
          plaintext={readOnly}
          className={`mt-2 ${readOnly && "border-primary border-opacity-25"}`}
          as={"textarea"}
          rows={5}
          name={"value"}
          value={partValue || ""}
          onChange={(e) => {
            setPartValue(e.target.value);
          }}
          readOnly={readOnly || partComplete}
          minLength={minLength}
          required
          isValid={showValidation && valid}
          isInvalid={showValidation && !valid}
        />
      </Form.Label>
    </FormGroup>
    {!readOnly &&
      <div>
        {minLength > 0 && (partValue?.length < minLength) &&
          <Form.Text className={"flex-grow-0"}>Enter at least {minLength} characters</Form.Text>
        }
        {maxLength && partValue?.length > maxLength &&
          <Form.Text className={"flex-grow-0 text-danger"}>Too many characters</Form.Text>
        }
        {maxLength &&
          <Form.Text
            className={`flex-grow-0 float-end text-end ${(partValue?.length || 0) > maxLength && "text-danger"}`}>
            {partValue?.length || 0}/{maxLength}
          </Form.Text>}
      </div>
    }
    {!readOnly &&
      <Button disabled={(!valid && !partComplete)}
              className={"mt-3 w-25"}
              variant="secondary"
              onClick={() => handleChangeComplete(!partComplete)}>
        Mark part as {partComplete ? "in" : ""}complete
      </Button>
    }
    <FormControl type={"hidden"} value={partComplete ? "true" : "false"}/>
  </Row>);
}

export default SingleInput;