import { Button, Modal, ModalTitle, Row, Stack } from "react-bootstrap";
import React from "react";
import { AccreditationType } from "graphql/__generated__/graphql";
import { LoadingSpinner } from "components/LoadingSpinner";

interface Props {
  onConfirm: () => void,
  accreditationType: AccreditationType,
}

const AbandonApplicationModal: React.FC<Props> = ({accreditationType, onConfirm}) => {

  const [ show, setShow] = React.useState(false);
  const [ deleting, setDeleting ] = React.useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleConfirm = () => {
    setDeleting(true);
    onConfirm();
  };

  return (
    <>
      <Button className={"btn btn-outline-danger btn-light"} onClick={handleShow} disabled={show}>
        <i className={"bi-trash"}></i> Delete application
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className={"border-0 align-items-start"}>
          <ModalTitle>Are you sure you want to delete your {accreditationType.name} application?</ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <p>Deleting your application will permanently remove all your progress and you will not receive a refund.</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          { deleting ? <><p>Please wait</p> <LoadingSpinner /></>:
          <Stack direction="horizontal" className="mx-auto" gap={4}>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button className={"btn-outline-danger btn-light"} onClick={handleConfirm}>
              Delete
            </Button>
          </Stack>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AbandonApplicationModal;