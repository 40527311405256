import React, { useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import IFormStep from "interfaces/IFormStep";
import { DocumentsBlock } from "components/Documents/DocumentsBlock";
import { useDocuments } from "hooks/useDocuments";
import ApplicationRequirementsBlock from "../Components/ApplicationRequirementsBlock";
import ISimpleNameValuePair from "interfaces/ISimpleNameValuePair";
import { LoadingSpinner } from "components/LoadingSpinner";

const Documents: React.FC<IFormStep> = ({formData, handleInputChange, requirements, models}) => {

  const { models: documentModels } = useDocuments();

  const [applicationDocumentRequirements, setApplicationDocumentRequirements] = React.useState<ISimpleNameValuePair[]>(formData.documents || []);

  useEffect(() => {
    handleInputChange('documents', applicationDocumentRequirements);
  }, [applicationDocumentRequirements]);

  const handleDocumentChange = (name: string, value: string, index: number) => {
    const appDocument = {
      name,
      value
    };

    setApplicationDocumentRequirements(prevState => {
      const existingDocument = prevState.find((doc) => doc.name === name);

      if (existingDocument) {
        return prevState.map((s, i) => {
          if (s.name === name) {
            return appDocument;
          }
          return s;
        })
      }

      return prevState.concat([appDocument])
    })
  }

  if (documentModels.loading) {
    return <><LoadingSpinner/></>
  }

  const documentOptionsData =
    documentModels.documents?.map(doc => ({id: doc.id as string, name: doc.name as string}));

  return (
    <>
      <Row>
        <h3 className="fw-bold mb-3">Documents</h3>
      </Row>
      <Row className={"mb-3"}>
        <h4>Attach documents that confirm your eligibility to be an accredited {models.application.accreditationType.name}.</h4>
        <h4>Any new documents can be uploaded using the form on the right.</h4>
      </Row>
      <Row className={"mb-3"}>
        <Col xs={6}>
          {requirements.documents?.map((requirementsGroup, index) => (
            <Row className={"mb-4"} key={`documents-block-${requirementsGroup.name}`}>
              <ApplicationRequirementsBlock
                {...requirementsGroup}
                handleChange={handleDocumentChange}
                selectOptions={documentOptionsData}
                applicationData={applicationDocumentRequirements}
              />
            </Row>
          ))}
        </Col>
        <Col sm={1}/>
        <Col xs={4}>
          <DocumentsBlock />
        </Col>
      </Row>
    </>
  );
};

export default Documents;