import React, { useEffect, useState } from "react";
import IAccreditationApplicationForm from "interfaces/IAccreditationApplicationForm";
import { MemberProfile } from "graphql/__generated__/graphql";
import IClinicalContact from "interfaces/IClinicalContact";
import ISupervisor from "interfaces/ISupervisor";
import IApplicationRequirements from "interfaces/IApplicationRequirements";
import ISimpleNameValuePair from "interfaces/ISimpleNameValuePair";
import IAdditionalReference from "interfaces/IAdditionalReference";
import IMember from "interfaces/IMember";
import _ from "lodash";
import { useAccreditationApplications } from "./useAccreditationApplications";
import { IAccreditationApplication } from "interfaces/IAccreditationApplication";
import { useApolloClient, useQuery } from "@apollo/client";
import { GET_PROFILE } from "graphql/profile/queries";
import Prerequisites from "../Steps/Prerequisites";
import YourDetails from "../Steps/YourDetails";
import YourExperience from "../Steps/YourExperience";
import ClinicalContact from "../Steps/ClinicalContact";
import Supervisors from "../Steps/Supervisors";
import Documents from "../Steps/Documents";
import SubmitAndPay from "../Steps/SubmitAndPay";
import { IStepConfig } from "../../../Form/FormStepper/FormStepper";
import { useApplicationValidator } from "./useApplicationValidator";

const relationshipReqs: ISimpleNameValuePair[] = [
  {name: "Head of Service / Clinical Manager", value: "headOfService"},
  {name: "Professional colleague", value: "professionalColleague"},
  {name: "Academic colleague", value: "academicColleague"},
  {name: "Clinical Supervision Group member", value: "clinicalSupervisionGroupMember"}
];

const defaultMemberProfile = {
  id: "",
  firstName: "",
  lastName: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
    country: "",
    regionId: "",
  },
  phone: "",
  email: "",
  qualifications: "",
  profession: ""
}

function applicationToForm(accreditationApplication: IAccreditationApplication): IAccreditationApplicationForm {
  const { accreditationApplicationId, accreditationType, applicationStatus, jsonData } = accreditationApplication;
  const appData = JSON.parse(jsonData);
  const { experiences, clinicalContacts, supervisors, additionalReference, documents, agreements } = appData;

  return {
    applicationId: accreditationApplicationId,
    accreditationTypeId: accreditationType.accreditationTypeId,
    applicationStatus,
    experiences,
    clinicalContacts,
    supervisors,
    additionalReference,
    documents,
    agreements
  };
}

function determineUniqueSupervisors(currentSupervisors: ISupervisor[] = [], clinicalContacts: IClinicalContact[] = []): ISupervisor[] {
  const uniqueCCSupervisors: IMember[] = _.uniqBy(clinicalContacts.map(cc => cc.supervisor), (x) => x.id);
  return uniqueCCSupervisors.map(ccs => (
    currentSupervisors.find(s => s.id === ccs.id) || ccs as ISupervisor
  )).filter(ccs => ccs.id);
}

interface OperationResult {
  success: boolean,
  message: string
}

export const useAccreditationApplicationFormComponent = (accreditationApplication: IAccreditationApplication) => {

  const { operations } = useAccreditationApplications();
  const { fetchApplications, saveApplication, submitApplication } = operations;

  const client = useApolloClient();
  const { data: profileData, loading: profileLoading, error: profileError } = useQuery(GET_PROFILE, { client });

  const [ loading, setLoading ] = useState<boolean>(true);

  const formValidator = useApplicationValidator(accreditationApplication.accreditationType);

  // Application form data
  const [application, setApplication] = useState<IAccreditationApplication>(accreditationApplication);
  const [formData, setFormData] = useState<IAccreditationApplicationForm>(applicationToForm(accreditationApplication));
  const [memberProfile, setMemberProfile] = useState<MemberProfile>(defaultMemberProfile);
  const [validated, setValidated] = useState<boolean>(false);
  const [operationResult, setOperationResult] = useState<OperationResult>();

  // Form visibility
  const [updating, setUpdating] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const handleValidateExperience = (_form: React.MutableRefObject<null>, callback: (success: boolean, message?: string[]) => void) => {
    const experienceValidation = formValidator.validateExperience(formData);
    if (!experienceValidation.length) return callback(true);
    return callback(false, ["Your experience doesn't match the minimum required."])
  }

  const handleValidateClinicalContacts = (_form: React.MutableRefObject<null>, callback: (success: boolean, message?: string[]) => void) => {
    const clinicalContactsValidation = formValidator.validateClinicalContacts(formData);
    if (!clinicalContactsValidation.length) return callback(true);
    return callback(false, clinicalContactsValidation)
  };

  const handleValidateSupervisors = (_form: React.MutableRefObject<null>, callback: (success: boolean, message?: string[]) => void) => {
    const experienceValidation = formValidator.validateSupervisors(formData);
    if (!experienceValidation.length) return callback(true);
    return callback(false, experienceValidation);
  };

  const handleValidateDocuments = (_form: React.MutableRefObject<null>, callback: (success: boolean, message?: string[]) => void) => {
    const documentsValidation = formValidator.validateDocuments(formData);
    if (!documentsValidation.length) return callback(true);
    return callback(false, documentsValidation);
  };

  const stepsConfig: IStepConfig[] = [
    { title: "Prerequisites", showSaveProgressButton: false, showNextButton: false, content: Prerequisites },
    { title: "Your details", showSaveProgressButton: false, content: YourDetails },
    { title: "Your experience", content: YourExperience, onChangeStep: handleValidateExperience },
    { title: "Clinical contact", content: ClinicalContact, onChangeStep: handleValidateClinicalContacts },
    { title: "Supervisors", content: Supervisors, onChangeStep: handleValidateSupervisors },
    { title: "Documents", content: Documents, onChangeStep: handleValidateDocuments },
    { title: "Review & Submit", showNextButton: false, showSaveProgressButton: false, content: SubmitAndPay }
  ];

  const requirements: IApplicationRequirements = {
    relationships: relationshipReqs,
    ...JSON.parse(accreditationApplication.accreditationType.requirementsJson || "{}")
  };

  useEffect(() => {
    if (!profileError && !profileLoading)
      setMemberProfile(profileData.profile);
  }, [profileData]);

  useEffect(() => {
    if (!profileLoading)
      setLoading(false);
  }, [profileLoading]);

  const handleSubmitApplication = async () => {

    if (updating) return;

    const applicationToSubmit: IAccreditationApplication = {
      ...application,
      jsonData: JSON.stringify(formData)
    };

    setUpdating(true);
    return submitApplication(applicationToSubmit).then((submittedApplication: IAccreditationApplication) => {
      setApplication(prevState => ({
        ...prevState,
        applicationStatus: submittedApplication.applicationStatus,
        lockedBy: submittedApplication.lockedBy,
        reviews: submittedApplication.reviews,
        updatedDate: application.updatedDate
      }));
    }).finally(() => {
      setUpdating(false);
    })
  }

  const handleApplicationUpdate = async () => {
    fetchApplications().then((applications: IAccreditationApplication[]) => {
      const updatedApplication =
          applications.find(a => a.accreditationApplicationId === application.accreditationApplicationId);
      if (updatedApplication) setApplication(updatedApplication);
    })
  }

  const handleSaveProgress = async () => {
    if (updating) return;

    setUpdating(true);

    const updatedApplication: IAccreditationApplication = {
      ...application,
      jsonData: JSON.stringify(formData)
    };

    return saveApplication(updatedApplication).then((application: IAccreditationApplication) => {
      if (!updatedApplication.accreditationApplicationId) {
        setFormData(prevState => ({
          ...prevState,
          applicationId: application.accreditationApplicationId
        }));
      }
      setApplication(application);
      setOperationResult({success: true, message: "Application saved successfully."});
      localStorage.removeItem("accreditationApplicationBackup");
    }).catch(error => {
      console.error("An error occurred saving the application", error);
      setOperationResult({success: false, message: "An error occurred saving the application."});
      localStorage.setItem("accreditationApplicationBackup", JSON.stringify(updatedApplication));
    }).finally(() => setUpdating(false));
  };

  const handleInputChangeEvent = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ): void => {
    const { name, value } = event.target;
    handleInputChange(name, value);
  };

  const handleInputChange = (
    name: string,
    value: string | string[] | boolean | IClinicalContact[] | ISupervisor[] | ISimpleNameValuePair[] | IAdditionalReference
  ): void => {

    setValidated(!!value);
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      }

      if (name === 'clinicalContacts') {
        return {
          ...updatedData,
          supervisors: determineUniqueSupervisors(updatedData.supervisors, updatedData.clinicalContacts)
        };
      }

      return updatedData;
    });
  };

  const handleNextStep = (back?: boolean) => {
    handleSaveProgress().then(() => {
      if (back) {
        setCurrentStep(Math.max(currentStep - 1, 1));
      } else {
        setCurrentStep(Math.min(currentStep + 1, stepsConfig.length));
      }
    });
  };

  const handleSetStep = (step: number) => {
    handleSaveProgress().then(() => {
      setCurrentStep(step);
    });
  }

  return {
    models: {
      formData,
      memberProfile,
      currentStep,
      updating,
      requirements,
      stepsConfig,
      application,
      loading,
      validated,
      operationResult
    },
    operations: {
      handleInputChangeEvent,
      handleInputChange,
      handleNextStep,
      handleSaveProgress,
      handleSetStep,
      handleSubmitApplication,
      handleApplicationUpdate
    }
  };
};