import { useQuery } from '@apollo/client';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { GET_PROFILE } from '../../../graphql/profile/queries';
import { Block } from '../../Blocks/Block';
import { LoadingSpinner } from '../../LoadingSpinner';

interface IExperience { }

const Experience: React.FC<IExperience> = ({

}) => {

    const { data, error, loading } = useQuery(GET_PROFILE);

    console.log("data PROFILE", data);

    return (
        <Block title="Experience">

            {loading ? <LoadingSpinner /> :
                <Row>
                    {data.profile.emdrLevel &&
                        <Col xs={12} >

                            <strong className="me-2">EMDR Level</strong>
                            <p> {data.profile.emdrLevel}  <small className="ms-4">({data.profile.emdrLevelDate})</small></p>
                        </Col>
                    }
                    {data.profile.emdrChildLevel &&
                        <Col xs={12} >

                            <strong className="me-2">EMDR Child Level</strong>
                            <p> {data.profile.emdrChildLevel} <small className="ms-4">({data.profile.emdrChildLevelDate})</small></p>

                        </Col>
                    }

                    {data.profile.professionalRegistration &&
                        <Col xs={12} >
                            <strong className="me-2">Professional Registration</strong>
                            <p> {data.profile.professionalRegistration} </p>
                        </Col>
                    }

                    {data.profile.profession &&
                        <Col xs={12} >

                            <strong className="me-2">Profession</strong>
                            <p>  {data.profile.profession}  </p>
                        </Col>
                    }

                </Row>
            }

        </Block>
    );
};

export default Experience;