import { Button } from "react-bootstrap";
import React from "react";

interface SaveProgressButtonProps {
  onClick?: () => Promise<any> | undefined
}

const SaveProgressButton: React.FC<SaveProgressButtonProps> = ({onClick}: SaveProgressButtonProps) => {

  const [ enabled, setEnabled ] = React.useState(true);

  const handleSaveProgressButton = () => {
    if (!onClick) return;
    setEnabled(false);
    onClick()?.then(() => {
      setEnabled(true)
    });
  };

  return (
    <div className={"float-end w-25"}>
      <Button disabled={!enabled} className={"btn-secondary w-50"} onClick={() => handleSaveProgressButton()}>{enabled ? "Save progress" : "Saving..."}</Button>
    </div>
  );

}

export default SaveProgressButton;