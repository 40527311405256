import React, {useEffect, useState} from "react";
import IApplicationReview from "interfaces/IApplicationReview";
import IApplicationReviewForm, {IPartData} from "interfaces/IApplicationReviewForm";
import useApplicationReview from "./useApplicationReview";
import SingleInput from "./Steps/SingleInput";
import AccordionInput from "./Steps/AccordionInput";
import PartD from "./Steps/PartD";
import Confirmation from "./Steps/Confirmation";
import ISimpleNameValuePair from "interfaces/ISimpleNameValuePair";
import IApplicationRequirements from "interfaces/IApplicationRequirements";
import {useQuery} from "@apollo/client";
import {GET_ACCREDITATION_TYPES} from "graphql/member";
import {AccreditationType, ReviewStatus} from "graphql/__generated__/graphql";
import {IStepConfig} from "../../Form/FormStepper/FormStepper";

function reviewToForm(applicationReview: IApplicationReview): IApplicationReviewForm {
  const { applicationReviewId, jsonData } = applicationReview;
  const appData = JSON.parse(jsonData || "{}");

  return {
    applicationReviewId: applicationReviewId,
    ...appData
  };
}

export const useSupervisorEvaluationFormComponent = (applicationReviewSummary: IApplicationReview) => {

  const { models, operations } = useApplicationReview(applicationReviewSummary);

  const { update } = operations;

  // Application form data
  const [applicationReview, setApplicationReview] = useState<IApplicationReview>(applicationReviewSummary);
  const [formData, setFormData] = useState<IApplicationReviewForm>(reviewToForm(applicationReviewSummary));
  const [requirements, setRequirements] = useState<IApplicationRequirements>();
  const [stepsConfig, setStepsConfig] = useState<IStepConfig[]>([]);

  // Form visibility
  const [updating, setUpdating] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const { data, error, loading } = useQuery(GET_ACCREDITATION_TYPES)

  useEffect(() => {
    if (!error && !loading) {
      const appType = data.activeAccreditationTypes.find((at: AccreditationType) =>
          at.accreditationTypeId === models.applicationReview.accreditationType.accreditationTypeId)
      setRequirements(JSON.parse(appType.requirementsJson));
    }
  }, [data, error, loading]);

  useEffect(() => {
    setApplicationReview(models.applicationReview);
    setFormData(reviewToForm(models.applicationReview));
  }, [models.loading, models.applicationReview]);

  useEffect(() => {
    const newStepsConfig: IStepConfig[] = (requirements?.evaluation || []).map(req => {
      return {
        title: req.title,
        content: req.requirements.length > 1 ? AccordionInput : SingleInput,
      }
    });

    setStepsConfig([
        ...newStepsConfig,
      { title: "Part D", content: PartD },
      { title: "Confirmation", showNextButton: false, content: Confirmation }
    ]);
  }, [requirements]);

  const handleSaveProgress = () => {
    if (!formData || updating) return;

    setUpdating(true);

    const updatedReview: IApplicationReview = {
      ...applicationReview,
      jsonData: JSON.stringify(formData),
      reviewStatus: ReviewStatus.InProgress
    };

    return update(updatedReview).then(() => {
      setApplicationReview(prevState => ({
        ...prevState,
        jsonData: JSON.stringify(formData)
      }));
    }).finally(() => {
      setUpdating(false);
    });
  };

  const handleInputChangeEvent = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ): void => {
    const { name, value } = event.target;
    handleInputChange(name, value);
  };

  const handleInputChange = (
    name: string,
    value: string | string[] | boolean | ISimpleNameValuePair[] | IPartData<unknown>
  ): void => {

    console.log(name, value);

    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handleSubmit = () => {
    update({
      ...applicationReviewSummary,
      jsonData: JSON.stringify(formData),
      reviewStatus: ReviewStatus.Complete
    }).then((response) => {
      return true;
    }).catch((error) => {
      console.error("error", error);
      return false;
    }).finally(() => {
      setUpdating(false);
    })
  }

  const handleNextStep = (back?: boolean) => {
    handleSaveProgress();
    if (back) {
      setCurrentStep(Math.max(currentStep - 1, 1));
    } else {
      setCurrentStep(Math.min(currentStep + 1, stepsConfig.length));
    }
  };

  const handleSetStep = (step: number) => setCurrentStep(step);

  return {
    models: {
      formData,
      currentStep,
      updating,
      stepsConfig,
      applicationReview,
      loading: models.loading,
      requirements
    },
    operations: {
      handleInputChangeEvent,
      handleInputChange,
      handleNextStep,
      handleSaveProgress,
      handleSetStep,
      handleSubmit
    }
  };
};