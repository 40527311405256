import React, { useEffect } from 'react';
import { Alert, Row } from "react-bootstrap";
import IFormStep from "interfaces/IFormStep";
import SupervisorEntry from "../Components/SupervisorEntry";
import ISupervisor from "interfaces/ISupervisor";

const Supervisors: React.FC<IFormStep> = ({formData, handleInputChange}) => {

  const [supervisors, setSupervisors] = React.useState<ISupervisor[]>(formData.supervisors);

  const handleChange = (name: string, value: string | boolean, index: number) => {
    setSupervisors((prevState) => {
      return prevState.map((s, i) => {
        if (i === index) {
          return {
            ...s,
            [name]: value
          }
        }

        if (name === "primary") {
          return {
            ...s,
            [name]: false
          };
        }

        return s;
      });
    })
  }

  useEffect(() => {
    handleInputChange('supervisors', supervisors);
  }, [supervisors]);

  useEffect(() => {
    setSupervisors(formData.supervisors);
  }, [formData.supervisors]);

  return (
    <>
      <Row>
        <h3 className="fw-bold mb-3">Supervisor details</h3>
      </Row>
      <Row>
        <h4>Tell us more about the supervisors you listed in your clinical contact records</h4>
      </Row>
      <Row>
        <h4>We'll contact them to get some more details about your practice.</h4>
      </Row>
      {
        <Row className="col-6 mb-4 mt-2">
          {!formData.supervisors?.length && <Alert variant={"warning"}>This will prepopulate with the supervisors selected on the previous page</Alert>}
        </Row>
        }
      {
        formData.supervisors && formData.supervisors?.map((supervisor, index) => (
            <Row className="col-6 mb-4" key={`clinicalContact[${index}]`} id={`clinicalContact[${index}]`}>
              <SupervisorEntry
                index={index}
                supervisor={supervisor}
                handleInputChangeEvent={(e) => handleChange(e.target.name, e.target.value, index)}
                handleCheckboxClick={(e) => handleChange(e.currentTarget.name, e.currentTarget.checked, index)}
              />
            </Row>
        ))
      }
    </>
  );
};

export default Supervisors;