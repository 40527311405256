import { Card, CardBody, CardHeader, Col, Form, FormGroup, Row } from "react-bootstrap";
import React, { ChangeEvent } from "react";
import ISupervisor from "interfaces/ISupervisor";

interface ISupervisorEntry {
  supervisor: ISupervisor,
  index: number,
  handleInputChangeEvent: (e: ChangeEvent<HTMLInputElement>) => void,
  handleCheckboxClick: (e: ChangeEvent<HTMLInputElement>) => void
}



const maxDate = new Date().toISOString().split("T")[0]

const SupervisorEntry: React.FC<ISupervisorEntry> = ({
                                                                 supervisor,
                                                                 index,
                                                                 handleInputChangeEvent,
                                                                 handleCheckboxClick}) => {

  const checkValid = (input: any) => input !== undefined && !!input;
  const checkInvalid = (input: any) => input !== undefined && !input;

  return (
    <Card className="bg-white border-0 p-0">
      <CardHeader className={"border-0 bg-transparent"}>
        <Card.Title className={"fw-bold"}>{supervisor.name}</Card.Title>
        <Card.Subtitle className={"text-muted"}>{supervisor.email}</Card.Subtitle>
      </CardHeader>
      <CardBody className={"text-black"}>
        <Row>
          <Col xs={10}>
            <FormGroup key={`supervisor[${index}].startDate`}>
              <Row>
                <Form.Label className={"fw-bold"}>When did {supervisor.name} start supervising you?</Form.Label>
              </Row>
              <Row className="mb-3">
                <Col xs={4}>
                  <Form.Control
                    name="startDate"
                    value={supervisor?.startDate || ""}
                    max={maxDate}
                    type="date"
                    required
                    onChange={handleInputChangeEvent}
                    isValid={checkValid(supervisor?.startDate)}
                    isInvalid={checkInvalid(supervisor?.startDate)}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup key={`supervisor[${index}].endDate`}>
              <Row>
                <Form.Label className={"fw-bold"}>When did this supervision end?</Form.Label>
              </Row>
              <Row className="mb-3 align-items-baseline">
                <Col xs={4}>
                  <Form.Control
                    name="endDate"
                    value={supervisor.ongoing ? "" : (supervisor?.endDate || "")}
                    type="date"
                    max={maxDate}
                    disabled={supervisor.ongoing}
                    onChange={handleInputChangeEvent}
                    isValid={checkValid(supervisor.ongoing) || checkValid(supervisor.endDate)}
                    isInvalid={checkInvalid(supervisor.ongoing) && checkInvalid(supervisor.endDate)}
                  />
                </Col>
                <Col>
                  <Form.Label className={"d-flex"}>
                    <Form.Check
                      className={"mb-2"}
                      name="ongoing"
                      onChange={handleCheckboxClick}
                      checked={supervisor.ongoing || false}
                      isValid={checkValid(supervisor.ongoing) || checkValid(supervisor.endDate)}
                      isInvalid={checkInvalid(supervisor.ongoing) && checkInvalid(supervisor.endDate)}
                    />
                    <span className={"mx-2"}>It's ongoing</span>
                  </Form.Label>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row className="me-auto float-end">
          <FormGroup key={`supervisor[${index}].isPrimary`}>
            <Form.Label className={"fw-bold d-flex"}>
              <Form.Check
                className={"mb-2 mx-2"}
                type={"radio"}
                name={"primary"}
                onChange={handleCheckboxClick}
                checked={supervisor.primary || false}
              />
              This is my primary supervisor
            </Form.Label>
          </FormGroup>
        </Row>
      </CardBody>
    </Card>);
}

export default SupervisorEntry;