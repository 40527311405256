import { Button, Col, Modal, Row, Stack } from "react-bootstrap";
import React from "react";

interface ModalData {
  [key: string]: {
    title: string;
    subtitle: string;
    body: string[];
  }
}

const modalData: ModalData = {
  evaluation: {
    title: "Evaluation",
    subtitle: "We've sent your evaluation to the applicant",
    body: [
      "Thank you for your feedback.",
      "We've sent an email to the applicant to let them know you have submitted your evaluation.",
      "We'll let you know if any further information is required."
    ],
  },
  application: {
    title: "Application",
    subtitle: "We've forwarded your application to your supervisors and your additional reference.",
    body: [
      "We've sent a confirmation to your email address.",
      "We'll let you know when your supervisors and additional reference have completed their parts of the application.",
      "Check in on your application any time from the Accreditations page"
    ]
  }
}

interface Props {
  formSubmitResult?: string;
}

const FormResultModal: React.FC<Props> = ({formSubmitResult}) => {

  const [ showModal, setShowModal ] = React.useState(!!formSubmitResult);

  if (!formSubmitResult) return <></>;

  const {title, subtitle, body} = modalData[formSubmitResult];

  return (
    <Modal onHide={() => {setShowModal(false)}} size={"lg"} show={showModal}>
      <Modal.Header closeButton className={"h2 bg-white border-0 align-items-start"}>
        {title} submitted
      </Modal.Header>
      <Modal.Body className={"text-center bg-light"}>
        <Row>
          <Col className={"offset-2"} xs={8}>
            <Row className={"mb-5 h3 fw-bold"}>
              {subtitle}
            </Row>
            <Row className={"text-black"}>
              {body.map(i => <p>{i}</p>)}
            </Row>
          </Col>
        </Row>
        <Modal.Footer className={"text-center align-content-center align-items-center"}>
          <Stack direction="horizontal" className="mx-auto" gap={4}>
            <a href={"/dashboard"} className={"btn btn-secondary"}>
              Go to Dashboard
            </a>
            <Button className={"btn btn-secondary"} onClick={() => setShowModal(false)}>
              Go to Accreditations
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default FormResultModal;