import { Button, ButtonProps, Modal, ModalTitle, Stack } from "react-bootstrap";
import React, { SyntheticEvent } from "react";

interface Props {
  title: string,
  modalContent: string,
  onConfirm: (event?: SyntheticEvent) => any,
  buttonText: string,
  showModal?: boolean,
  buttonProps?: ButtonProps,
}

const ConfirmationModal: React.FC<Props> = ({title, modalContent, onConfirm, buttonText, showModal = true, buttonProps = {}}) => {

  const [ show, setShow ] = React.useState<boolean>(false);

  const handleButtonClick = (event: React.SyntheticEvent) => showModal ? setShow(true) : onConfirm(event);
  const handleClose = () => setShow(false);

  return (
  <>
    <Button variant={"primary"} onClick={handleButtonClick} {...buttonProps}>{buttonText}</Button>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className={"border-0 align-items-start"}>
        <ModalTitle>{title}</ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <p>{modalContent}</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Stack direction="horizontal" className="mx-auto" gap={4}>
          <Button variant="secondary" onClick={onConfirm}>{buttonText}</Button>
          <Button variant="primary" onClick={handleClose}>Cancel</Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  </>);
}

export default ConfirmationModal;