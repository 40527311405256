import React, { useEffect } from 'react';
import { Button, Card, CardBody, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_APPLICATION_REVIEWS } from "graphql/member";
import IApplicationReviewSummary from "interfaces/IApplicationReviewSummary";
import { ReviewerRole } from "../../../graphql/__generated__/graphql";

const useApplicationReviews = () => {

    const [ applicationReviews, setApplicationReviews ] = React.useState<IApplicationReviewSummary[]>();

    const { data, loading, error} = useQuery(GET_APPLICATION_REVIEWS);

    useEffect(() => {
        if (!loading && !error) {
            setApplicationReviews(data.accreditationApplicationReviewSummaries);
        }
    }, [data, loading, error]);

    return {
        models: {
            applicationReviews,
            loading
        }
    };
}

const ReviewsBlock: React.FC = () => {

    const { models } = useApplicationReviews();
    const { applicationReviews } = models;

    const navigate = useNavigate();

    const handleOnSelectReview = (reviewSummary: IApplicationReviewSummary) => {
        navigate("/accreditation/review", { state: { review: reviewSummary } });
    };

    return (applicationReviews?.length ?
        <>
            <Row className={"col-9"}>
                <h2 className="fs-3 fw-bold mb-3">Reviews</h2>
            </Row>
            {applicationReviews?.map(review =>
              <Row key={review.applicationReviewId} className={"mb-3"}>
                  <ReviewBlock
                    review={review}
                    handleOnSelectReview={() => handleOnSelectReview(review)}
                  />
              </Row>
            )}
    </> : <></>);
};

interface ReviewBlockProps {
    review: IApplicationReviewSummary;
    handleOnSelectReview: (review: IApplicationReviewSummary) => void;
}

const ReviewBlock: React.FC<ReviewBlockProps> = ({review, handleOnSelectReview}) => {
    const isReference = review.reviewerRole === ReviewerRole.AdditionalReference;

    return (
        <Card className={`border-0 w-75`}>
            <CardBody className={"mb-0"}>
                <dl className={"d-flex mb-0"}>
                    <dt>{isReference ? "Reference request" : "Evaluation"}</dt>
                    <dd className={"ms-2"}>{review.applicant.name}</dd>
                </dl>
                <dl className={"d-flex mb-0"}>
                    <dt>Requested date</dt>
                    <dd className={"ms-2"}>{new Date(review.requestedDate).toLocaleDateString()}</dd>
                </dl>
              <Button className={"mx-auto btn-outline-info text-primary bg-white"}
                    onClick={() => handleOnSelectReview(review)}>
                  {isReference ? "Complete reference" : "Complete evaluation"}
              </Button>
            </CardBody>
        </Card>
    );
}

export default ReviewsBlock;