import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, FormControl, Spinner, Stack, Table } from 'react-bootstrap';
import { GET_EVENT_BY_POST_ID } from '../../graphql/event/queries';
import { Event } from '../../graphql/__generated__/graphql';
import { PayPalButton } from '../Form/PaymentButton/PayPalButton';

interface EventDetailsProps {
    postId: string,
    initialQuantity?: number,
    guest?: boolean,
    onLogin?: () => void
}

const EventDetails: React.FC<EventDetailsProps> = ({ postId, initialQuantity, guest }) => {

    const { data, loading, error } = useQuery(GET_EVENT_BY_POST_ID, { variables: { id: postId } });

    const [event, setEvent] = useState<Event>();
    const [expired, setExpired] = useState<boolean>();
    const [qty, setQty] = useState(initialQuantity ?? 1)

    const [cart, setCart] = useState<any>(null);

    const [complete, setComplete] = useState(false);

    const handleExit = (error: object | null, metadata: object) => {
        console.log("Exit", error, metadata);
    };

    const getPrice = (quantity?: number) => {
        var price = (guest ? event?.nonMemberPrice ?? 0 : event?.memberPrice ?? 0);

        price = quantity ? price * quantity : price;

        return price.toFixed(2);
    }

    useEffect(() => {
        if (data && data.eventByPostId) {

            const event = data.eventByPostId;
            const eventEndDate = new Date(event.endDate);
            setExpired(eventEndDate < new Date());
            setEvent(data.eventByPostId);
            const newCart = { items: [{ id: data.eventByPostId?.eventId, quantity: qty.toString() }] };
            setCart(newCart);
        }
    }, [loading, data, error, qty]);



    return ((loading) ? <Spinner /> :
        <div>
            <h2 className="mb-4">
                {event?.name}
            </h2>


            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction="horizontal" gap={4} className="mb-3">
                    <span>
                        <Badge pill className="me-1 p-2"><i className="bi bi-calendar "></i></Badge>
                        {new Date(event?.startDate).toLocaleDateString("en-gb")}
                    </span>
                    <span>
                        <Badge pill className="me-1 p-2"><i className="bi bi-clock"></i></Badge>
                        {new Date(event?.startDate).toLocaleTimeString("en-gb")}
                    </span>
                </Stack>
                <span>
                    <Button
                        size="sm"
                        style={{ borderRadius: '99px' }}
                        variant="outline-primary"
                        as={"a"}
                        href={event?.eventPostHref ?? "#"}
                        className="me-1"
                    >
                        <i className="bi bi-info-circle me-2"></i><small>about this event</small>
                    </Button>
                </span>
            </div>
            <Alert variant="light" className="text-primary">
                <Table>
                    <thead>
                        <th></th><th>Price</th><th className="text-center">Qty.</th><th>Total</th>
                    </thead>
                    <tbody>
                        <tr style={{ verticalAlign: 'middle' }}>
                            <td >General Admission</td>
                            <td>&pound; {getPrice()}</td>
                            <td style={{ width: '80px' }} >
                                <FormControl
                                    value={qty}
                                    onChange={e => setQty(parseInt(e.target.value))}
                                    type="number"
                                    min={1}
                                    disabled={complete}
                                />
                            </td>
                            <td>&pound; {getPrice(qty)}</td>
                        </tr>
                    </tbody>
                </Table>

                {complete && <h2 className="my-4"><i className="bi bi-check-lg me-2"></i>Payment Complete  </h2>}

                <p>You have {complete ? <b>sucessfully purchased</b> : "requested"} {qty} participant{qty > 0 ? "s" : ""} to attend this event.

                    {complete ? "You will recieve an email to confirm your purchase." : "If this is incorrect please adjust the quantity before proceeding."}

                </p>

            </Alert>


            {expired ? <p>This event has now finished.</p> :

                complete ?
                    <div className="w-100 text-center">
                        {guest ? <b className="text-info">You may now close this page.</b> : <Button as={"a"} href="/dashboard" size="lg">Go To Dashboard</Button>}
                    </div> :
                    (cart?.items &&
                        <div className="d-grid">
                            <PayPalButton
                                disabled={(qty < 1)}
                                cart={cart}
                                environment={"sandbox"}
                                onExit={handleExit}
                                onSuccess={() => setComplete(true)}
                                label={<> <i className="bi bi-basket me-2"></i>Pay <b className="ms-1"><small>(&pound; {((event?.memberPrice ?? 0) * qty).toFixed(2)})</small></b></>} />

                        </div>)}

        </div>
    );


};

export default EventDetails;




