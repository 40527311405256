import React, { useEffect } from 'react';
import { Alert, Card, CardBody, Col, Row } from "react-bootstrap";
import IFormStep from "interfaces/IFormStep";
import ISimpleNameValuePair from "interfaces/ISimpleNameValuePair";
import _ from "lodash";
import ApplicationReviewBlock from "../Components/ApplicationReviewBlock";
import AccreditationPaymentModal from "../../AccreditationPayment/AccreditationPaymentModal";
import ConfirmationModalButton from "../../ApplicationReviewForm/ConfirmationModal";
import { useApplicationValidator } from "../Hooks/useApplicationValidator";

const agreementsCheckboxes = [
  { name: "agreement-1", label: "Once you've submitted your form you'll be unable to make any changes." },
  { name: "agreement-2", label: "Your accreditation fee is non-refundable" },
  { name: "agreement-3", label: "Your accreditation will last for 5 years, after which, to continue being EMDR Europe accredited, you'll need to review it. This will require documentation of CPD." }
]

const SubmitAndPay: React.FC<IFormStep> = ({formData, handleFeedback, handleInputChange, models, requirements, handleSubmitApplication, handleApplicationUpdate}) => {

  const [agreements, setAgreements] = React.useState<ISimpleNameValuePair[]>(formData.agreements ?? []);
  const [formErrors, setFormErrors] = React.useState<string[]>([]);

  const formValidator = useApplicationValidator(models.application.accreditationType);

  useEffect(() => {
    setFormErrors(formValidator.validateForm(formData));
  }, [formData]);

  const { application } = models;

  useEffect(() => {
    if (agreements)
      handleInputChange("agreements", agreements);
  }, [agreements]);

  const handleAgreementChange = (name: string, value: boolean) =>
    setAgreements((prevState) => _.unionBy([{name, value}], prevState,'name'));

  const handlePaymentSuccess = () => {
    handleFeedback("info", "Payment was successful");
    handleApplicationUpdate();
  };

  const merged = _.merge(_.keyBy(agreementsCheckboxes, "name"), _.keyBy(agreements, "name"));
  const formCheckboxes = _.values(merged);

  return (
    <>
      <Row className={"col-6"}>
        <h3 className={"fw-bold mb-3"}>Review</h3>
        <h4>Please take a moment to review your application carefully before proceeding to ensure all information is accurate and complete.</h4>
      </Row>
      {formErrors.length > 0 &&
        <Row>
          <Col xs={5}>
            <Alert variant={"warning"}>
              <Alert.Heading>
                Application contains errors. These must be fixed before you can continue.
              </Alert.Heading>
              <ul>
                {formErrors.map(err => <li>{err}</li>)}
              </ul>
            </Alert>
          </Col>
        </Row>
      }
      <Row className={"col-6 mb-3"}>
        <Card className={"border-0 bg-transparent"}>
          <CardBody>
            <ApplicationReviewBlock {...models} requirements={requirements}/>
          </CardBody>
        </Card>
      </Row>
      <Row className={"mt-2"}>
        <Col xs={5}>
          {
            !formErrors.length &&
              (application.isPaid ?
                <ConfirmationModalButton
                  title={"Confirm application submission"}
                  buttonText={"Submit for review"}
                  modalContent={"By clicking below you are confirming that your application is accurate and ready for evaluation."}
                  onConfirm={handleSubmitApplication}
                />
                :
                <AccreditationPaymentModal
                  handleAgreementChange={handleAgreementChange}
                  agreementChecks={formCheckboxes}
                  application={application}
                  onPaymentSuccess={handlePaymentSuccess}
                />
              )
          }
        </Col>
      </Row>
    </>
  );
};

export default SubmitAndPay;