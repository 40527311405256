import { BillingRequest, BillingRequestFlow } from '@gocardless/react-dropin';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, ModalTitle, Row, Stack } from 'react-bootstrap';
import { useAccreditationPayments } from "hooks/useAccreditationPayments";
import { IAccreditationApplication } from "interfaces/IAccreditationApplication";
import { GoCardlessButton } from "../../Form/PaymentButton/GoCardlessButton";
import { LoadingSpinner } from "../../LoadingSpinner";
import _ from "lodash";

const usePaymentModal = (application: IAccreditationApplication) => {

    const { operations } = useAccreditationPayments();

    const [show, setShow] = useState(false);

    const [flowId, setFlowId] = useState<string | null>(null);
    const [envId, setEnvId] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
        setFlowId(null);
        setEnvId("");
    }

    const handleSetupPayment = () => {
        setLoading(true);
        operations.initialiseApplicationPayment(application).then((payment) => {
            if (payment) {
                setFlowId(payment.flow_id);
                setEnvId(payment.environment ?? "")
            } else {
                setFlowId(null);
                setEnvId("");
            }
        }).catch(error => {
            console.error("error", error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleExit = (error: any, metadata: any) => {
        // TODO: Handle this
        console.log("error", error);
        console.log("metadata", metadata);
    }

    const handleSuccess = (billingRequest: BillingRequest, billingRequestFlow: BillingRequestFlow) => {
        setLoading(true);
        return operations.finaliseApplicationPayment(application, billingRequest, billingRequestFlow).then(_r => {
            setFlowId(null);
            setEnvId("");
            setLoading(false);
            setShow(false);
            return this;
        });
    };

    return {
        models: { show, envId, flowId, loading },
        operations: { handleShow, handleClose, handleSetupPayment, handleSuccess, handleExit }
    }
}

interface Props {
    handleAgreementChange: (field: string, value: boolean) => void,
    onPaymentSuccess: () => void,
    agreementChecks: any[],
    application: IAccreditationApplication
}

const AccreditationPaymentModal: React.FC<Props> = ({handleAgreementChange, onPaymentSuccess, agreementChecks, application}) => {

    const { models, operations } = usePaymentModal(application);
    const { handleShow, handleClose, handleSuccess, handleExit, handleSetupPayment } = operations;
    const { show, flowId, envId, loading} = models;

    const handlePaymentSuccess = (billingRequest: BillingRequest, billingRequestFlow: BillingRequestFlow) =>
        handleSuccess(billingRequest, billingRequestFlow).then(() => {
            onPaymentSuccess();
        });

    useEffect(() => {
        if (!flowId && !loading) {
            handleSetupPayment();
        }
    }, [loading, flowId]);

    if (!flowId || loading) return <LoadingSpinner />

    return (
      <>
        <Button className="btn btn-primary" onClick={handleShow}>
            Continue to payment
        </Button>
          <Modal show={show} onHide={handleClose} size={"lg"}>
              <Modal.Header closeButton className={"border-0 align-items-start"}>
                  <ModalTitle>Next, you'll need to pay your &pound;{application.accreditationType.price} accreditation fee to submit your application.</ModalTitle>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                      {agreementChecks.map(({name, label, value}) => (
                        <Row key={name}>
                            <Form.Label className={"d-flex"}>
                                <Form.Check
                                  style={{"paddingRight": "1em"}}
                                  required
                                  onChange={(e) => handleAgreementChange(name, e.currentTarget.checked)}
                                  checked={value as boolean || false}
                                />
                                {label}
                            </Form.Label>
                        </Row>
                      ))}
                  </Row>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                  <Stack direction="horizontal" className="mx-auto" gap={4}>
                      <Button variant="secondary" onClick={handleClose}>
                          Close
                      </Button>
                      <GoCardlessButton
                        disabled={!(_.every(agreementChecks.map(a => a.value)))}
                        billingRequestFlowID={flowId as string}
                        environment={envId}
                        onExit={handleExit}
                        onSuccess={handlePaymentSuccess}
                        label={<>Checkout</>}
                      />
                  </Stack>
              </Modal.Footer>
          </Modal>
      </>
    );



};

export default AccreditationPaymentModal;