import IClinicalContact from "../../interfaces/IClinicalContact";
import React from "react";
import { Card, CardBody, Col, Row } from "react-bootstrap";

interface CCRProps {
  cc: IClinicalContact;
  showSupervisor?: boolean;
}

const ClinicalContactReviewEntry: React.FC<CCRProps> = ({ cc, showSupervisor = true }) => {
  return (
    <Card className={"bg-light-subtle border-1 mb-2 p-0 rounded-top-4"}>
      <CardBody>
        <Row>
          <Col>
            <Row>
              <Col xs={5}><strong>Client:</strong> {cc.initials} {cc.age && <span>({cc.age})</span>}</Col>
              <Col xs={7}><strong>Start date:</strong> {cc.startDate}</Col>
            </Row>
            <Row>
              <Col><strong>Sessions:</strong> {cc.numberOfSessions} conducted at {cc.treatmentSetting}</Col>
            </Row>
            {showSupervisor &&
              <Row>
                <Col><strong>Supervisor</strong>: {cc.supervisor.name}</Col>
              </Row>
            }
          </Col>
          <Col xs={6}><strong>Presenting problem:</strong> {cc.presentingProblem}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ClinicalContactReviewEntry;