import React, { useEffect } from "react";
import IEvaluationFormStep from "interfaces/IEvaluationFormStep";
import { Button, Form, FormGroup, Row } from "react-bootstrap";
import ISimpleNameValuePair from "interfaces/ISimpleNameValuePair";

function generateCheckboxes(applicantName: string, minimumHours: number): ISimpleNameValuePair[] {
  return [
    {name: `I confirm that ${applicantName} has completed a minimum of ${minimumHours} Hours Clinical Supervision/Consultation.`, value: false},
    {name: `I confirm that I have personally supervised the work of ${applicantName} as outlined in this reference and
    competency framework document in relation to each of the cases listed.`, value: false},
    {name: `I confirm that ${applicantName} has conformed to the level of attainment as stated and prescribed by the Association.`, value: false},
    {name: `I attest that this is an honest and valid evaluation of ${applicantName}'s competencies.`, value: false},
    {name: `I understand that if for any reason information is forthcoming to suggest that the above conditions were not met
    the consultant and/or applicant may forfeit their accreditation and possibly Association membership.`, value: false}
  ];
}

const Confirmation: React.FC<IEvaluationFormStep> = ({ models }) => {

  const [checkboxes, setCheckboxes] = React.useState<ISimpleNameValuePair[]>([]);

  const handleCheckboxClick = (index: number, value: boolean) => {
    setCheckboxes((prevState) => {
      return prevState.map((c, i) => {
        return {
          ...c,
          value: i === index ? value : c.value
        }
      })
    });
  };

  useEffect(() => {
    const hoursOfSupervisionReq = models.requirements.experience.find((req: any) => req.name === "hours-of-supervision");
    setCheckboxes(generateCheckboxes(models.applicationReview.applicant.name, hoursOfSupervisionReq.min));
  }, []);

  return (<Row className={"col-8"}>
    <h2>Confirmation</h2>
    {}
    <FormGroup>
      {checkboxes.map(({name, value}, index) => (
        <Row key={"confirm-"+index}>
          <Form.Label className={"d-flex"}>
            <Form.Check
              style={{"paddingRight": "1em"}}
              required
              onChange={(e) => handleCheckboxClick(index, e.currentTarget.checked)}
              checked={value as boolean}
            />
            {name}
          </Form.Label>
        </Row>
      ))}
      <Row className={"col-4 mt-2"}>
        <Button type={"submit"} variant={"secondary"} disabled={checkboxes.some(c => !c.value)}>Submit supervisor evaluation</Button>
      </Row>
    </FormGroup>
  </Row>);
}

export default Confirmation;