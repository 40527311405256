import React from 'react';
import { Alert, Button, Card, CardBody, Col, Row } from "react-bootstrap";
import IFormStep from "interfaces/IFormStep";

const Prerequisites: React.FC<IFormStep> = ({formData, handleNext, requirements}) => {

  const additionalRefPDF = requirements.links?.additionalReferencePdf || process.env.REACT_APP_ADDITIONAL_REFERENCE_PDF_URL;

  return (
    <>
      <Row>
        <Col xs={6}>
          <h3 className="fw-bold mb-3">What you'll need</h3>
          <p className="text-black">Your progress will be saved as you go, so you can make a start now and come back later to finish it.</p>
        </Col>
      </Row>
      <Row>
        <h4>To complete this form you'll need:</h4>
      </Row>
      <Row className="mb-4">
        <Col xs={3}>
          <Card border="light" className="h-100 p-2">
            <CardBody>
              <Card.Title className="mb-3">
                <i className="bi bi-person-vcard h1 text-white block-bg-dark-blue fs-3 rounded-circle p-2"></i>
              </Card.Title>
              <Card.Text className="text-black">
              Contact details and accreditation info for your supervisor.&nbsp;
                <a title={"Check eligibility link"} href="https://emdrassociation.org.uk/become-an-accredited-therapist/training/">Check you are eligible to apply</a>.
              </Card.Text>
            </CardBody>
          </Card>
        </Col>
        <Col xs={3}>
          <Card border="light" className="h-100 p-2">
            <CardBody>
              <Card.Title className="mb-3">
                <i className="bi bi-clipboard-check h1 block-bg-ocean text-black fs-3 rounded-circle p-2"></i>
              </Card.Title>
              <Card.Text className="text-black">
                Copies of relevant training certificates and professional registration.
              </Card.Text>
            </CardBody>
          </Card>
        </Col>
        <Col xs={3}>
          <Card border="light" className="h-100 p-2">
            <CardBody>
              <Card.Title className="mb-3">
                <i className="bi bi-file-earmark-pdf h1 block-bg-lime fs-3 rounded-circle p-2"></i>
              </Card.Title>
              <Card.Text className="text-black">
                Your second reference as a PDF to upload. Get the template <a title={"Additional reference template link"} rel={"noreferrer"} target={"_blank"} href={additionalRefPDF}>here</a>.
              </Card.Text>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
        <p>
          <Button className="btn btn-primary" title={`${formData.applicationId ? "Continue" : "Start"} your accreditation application`} onClick={() => handleNext()}>
            {formData.applicationId ? "Continue" : "Start"} your accreditation application
            <i className="bi bi-chevron-right"></i>
          </Button>
        </p>
      </Row>
      <Row>
        <Col xs={6}>
        <Alert variant="warning" title="Next" >
          <Alert.Heading className="fs-3 mb-3"><i className="bi bi-info-circle mx-1"></i> Next:</Alert.Heading>
          <div className="mx-3">
            <ol>
              <li>After you've completed this form, your supervisor(s) will be notified and will begin your Evaluation.</li>
              <li>You'll be able to review their Evaluation and ask for changes.</li>
              <li>During that process you'll also be asked to make a payment for your accreditation fee. <a title={"Link to fees and bank details"} href="https://emdrassociation.org.uk/for-professionals/accreditation/">Fees can be found here</a>
              </li>
            </ol>
          </div>
        </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Prerequisites;