import { Accordion, Button, Col, Form, FormControl, FormGroup, InputGroup, Row, Stack } from "react-bootstrap";
import React, {useEffect, useRef} from "react";
import {FeedbackLevel} from "../../../../interfaces/FeedbackLevel";

interface Props {
    name: string,
    title: string,
    subtitle: string,
    elements: string[],
    value?: any,
    handleChange: (name: string, value: any) => void,
    readOnly?: boolean,
    formRef: React.MutableRefObject<any>,
    handleFeedback: (level: FeedbackLevel, message: string) => void,
    minLength?: number,
    maxLength?: number,
}

const AccordionItem: React.FC<Props> = ({
                                          name,
                                          title,
                                          subtitle,
                                          elements,
                                          value,
                                          handleChange,
                                          readOnly = false,
                                          handleFeedback,
                                          minLength = 1,
                                          maxLength
                                      }) => {

    const [sectionComplete, setSectionComplete] = React.useState<boolean>(value?.complete || false);
    const [sectionValue, setSectionValue] = React.useState<string>(value?.value);
    const [showValidation, setShowValidation] = React.useState(false);

    const formControlRef = useRef<any>();

    useEffect(() => {
      handleChange(name, {
        value: sectionValue,
        complete: sectionComplete,
      })
    }, [sectionComplete, sectionValue]);

    const handleChangeComplete = (value: boolean) => {
      setShowValidation(true);
      if (value) {
        const result = formControlRef.current && formControlRef.current.checkValidity();
        if (!result) {
          handleFeedback("danger", "Cannot mark as complete whilst section has errors");
          return;
        }
      }
      setSectionComplete(value);
    }

    const valid = (sectionValue !== undefined && sectionValue?.length >= minLength &&
      sectionValue?.length <= (maxLength ?? Number.MAX_SAFE_INTEGER));

    return <Accordion.Item eventKey={name}>
        <Accordion.Header>{sectionComplete &&
            <i className={"bi bi-check2-circle me-2 fs-4"}/>}{title}</Accordion.Header>
        <Accordion.Body className={"text-black"}>
          <FormGroup>
            <Form.Label className={"text-black"}>
              <p className={"fw-bold"}>{subtitle}</p>
              {
                elements?.length && <ul>
                  {elements.map((e, i) => <li key={i}>{e}</li>)}
                </ul>
              }
              <FormControl
                ref={formControlRef}
                plaintext={readOnly}
                className={`mt-2 ${readOnly && "border-bottom-0 border-primary border-opacity-25"}`}
                as={"textarea"}
                rows={5}
                name={name}
                value={sectionValue || ""}
                onChange={(e) => {
                  setSectionValue(e.target.value);
                }}
                readOnly={readOnly || sectionComplete}
                minLength={minLength}
                required
                isValid={showValidation && valid}
                isInvalid={showValidation && (sectionValue !== undefined && !valid)}
              />
            </Form.Label>
          </FormGroup>
          <div>
          {minLength > 0 && sectionValue?.length < minLength &&
            <Form.Text >Enter at least {minLength} characters</Form.Text>
          }
          {maxLength && sectionValue?.length > maxLength &&
            <Form.Text className={"float-start text-danger"}>Too many characters</Form.Text>
          }
          {maxLength && !readOnly &&
            <Form.Text className={`float-end text-end ${(sectionValue?.length || 0) > maxLength && "text-danger"}`}>
              {sectionValue?.length || 0}/{maxLength}
            </Form.Text>}
          </div>
          {!readOnly &&
            <Button disabled={(!valid && !sectionComplete)}
                    className={"mt-3 w-25"}
                    variant="secondary"
                    onClick={() => handleChangeComplete(!sectionComplete)}>
              Mark section as {sectionComplete ? "in" : ""}complete
            </Button>
          }
          <FormControl type={"hidden"} value={sectionComplete ? "true" : "false"}/>

        </Accordion.Body>
    </Accordion.Item>
}

export default AccordionItem;