import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from "../../LoadingSpinner";
import ApplicationBlock from "../ApplicationBlock";
import { useNavigate } from "react-router-dom";
import { useAccreditationApplications } from "../ApplicationForm/Hooks/useAccreditationApplications";
import { IAccreditationApplication } from "interfaces/IAccreditationApplication";
import IApplicationReview from "interfaces/IApplicationReview";
import { LockOwner } from "../../../graphql/__generated__/graphql";

const ApplicationsBlock: React.FC = () => {

    const { models, operations } = useAccreditationApplications();
    const { loading, accreditationTypes, applications } = models;

    const navigate = useNavigate();

    const handleOnSelectApplication = (application: IAccreditationApplication, action: string) => {
        navigate("/accreditation/" + action, { state: { application } });
    };

    const handleOnAbandonApplication = async (application: IAccreditationApplication) => {
        await operations.deleteApplication(application);
    };

    const handleOnSubmitFinalApplication = async (application: IAccreditationApplication)=> {
      await operations.submitApplication(application, LockOwner.Emdr);
    }

    const handleOnSelectReview = (appRev: IApplicationReview, application: IAccreditationApplication) =>
      navigate("/accreditation/review", {
        state:
          {
            review: {
              ...appRev,
              accreditationType: application.accreditationType,
            }
          }
      });

    return (loading ? <LoadingSpinner/> :
        <>
            {
            accreditationTypes.map((accreditationType, index) => {
                const application =
                  applications.find(app => app.accreditationType.accreditationTypeId === accreditationType.accreditationTypeId)
                  ??
                  { accreditationType: accreditationType, jsonData: "{}", lockedBy: LockOwner.Applicant };
                return (
                  <Row key={`accreditation-${index}`}>
                      <Col>
                          <ApplicationBlock
                            application={application}
                            accreditationType={accreditationType}
                            handleOnSelectReview={(review) => handleOnSelectReview(review, application)}
                            handleOnSelectApplication={() => handleOnSelectApplication(application, "apply")}
                            handleOnViewApplication={() => handleOnSelectApplication(application, "view")}
                            handleOnAbandonApplication={() => handleOnAbandonApplication(application)}
                            handleOnSubmitApplication={() => handleOnSubmitFinalApplication(application)}
                          />
                      </Col>
                  </Row>
                )
            })
            }
        </>
    );
};

export default ApplicationsBlock;