import React from 'react';
import { useDocuments } from '../../hooks/useDocuments';
import { Block } from '../Blocks/Block';
import { LoadingError } from '../LoadingError';
import { LoadingSpinner } from '../LoadingSpinner';
import { NewDocumentDialog } from './NewDocumentDialog';

interface Props {
    alwaysShowUpload?: boolean;
}

export const DocumentsBlock: React.FC<Props> = ({alwaysShowUpload = false}) => {

    const { models } = useDocuments();
    const { dueToExpiry } = models;

    const callToAction =
        <>
            <NewDocumentDialog buttonTitle="Upload Document"></NewDocumentDialog>
        </>;

    const showCallToAction = alwaysShowUpload || (models.documents && models.documents.length > 0);

    return (
        <Block title={"My Documents"} callToAction={showCallToAction ? callToAction : undefined} >
            {models.loading && <LoadingSpinner></LoadingSpinner>}
            {models.error && <LoadingError></LoadingError>}
            {!models.loading && !models.error && <>
                <p>You have <b>{models?.total?.toString()}</b> documents in total.</p>
                <p>
                    There are <b className={(dueToExpiry && dueToExpiry > 0) ? "text-danger" : ""}>{dueToExpiry?.toString()}</b> documents that have expired.
                </p>

            </>
            }
        </Block>
    );
}