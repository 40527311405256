import React, { useEffect } from "react";
import IEvaluationFormStep from "interfaces/IEvaluationFormStep";
import { Accordion, FormGroup, Row } from "react-bootstrap";
import AccordionItem from "./AccordionItem";
import _ from "lodash";
import ISimpleNameValuePair from "../../../../interfaces/ISimpleNameValuePair";

const AccordionInput: React.FC<IEvaluationFormStep> = ({formData, formRef, handleFeedback, requirementsGroup, handleInputChange, readOnly = false}) => {

  const [partBData, setPartBData] = React.useState<ISimpleNameValuePair[]>(formData[requirementsGroup.name] || []);

  const handleOnChange = (name: string, value: any) => {
    setPartBData((prevState) => _.unionBy([{name, value}], prevState,'name'));
  }

  const partBMerged = _.merge(_.keyBy(requirementsGroup.requirements, "name"), _.keyBy(partBData, "name"));
  const mergedReqs = _.sortBy(_.values(partBMerged), "sortKey");

  useEffect(() => {
    handleInputChange(requirementsGroup.name, partBData);
  }, [partBData]);

  return (<Row className={"col-8"}>
    <h2>{requirementsGroup.pageTitle} {partBData.every(b => b.value?.complete) && <i className={"bi bi-check2-circle me-2 fs-4"}/>}</h2>
    <p className={"text-black"}>{requirementsGroup.pageSubtitle}</p>
    <FormGroup>
      <Accordion defaultActiveKey={mergedReqs.find(r => !r.value?.complete)?.name}>
        {mergedReqs.map(req =>
          <AccordionItem
              key={req.name}
              readOnly={readOnly}
              handleChange={handleOnChange}
              value={req.value}
              formRef={formRef}
              handleFeedback={handleFeedback}
              {...req}
          />
        )}
      </Accordion>
    </FormGroup>
  </Row>);
}

export default AccordionInput;