import { Accordion, Button, CardBody, Col, Form, FormGroup, Row } from "react-bootstrap";
import React, { ChangeEvent } from "react";
import IClinicalContact from "interfaces/IClinicalContact";
import MemberSelect from "components/Form/MemberSelect/MemberSelect";
import IMember from "interfaces/IMember";

interface IClinicalContactEntry {
  contact?: IClinicalContact,
  index: number,
  handleInputChangeEvent: (e: ChangeEvent<HTMLInputElement>) => void,
  handleInputChange: ((name: string, value: string | IMember) => void),
  handleDelete: () => void,
  autoFocus?: boolean,
  ref?: boolean,
  showAge?: boolean
}

const today = new Date().getDate();

const ClinicalContactEntry: React.FC<IClinicalContactEntry> = ({
                                                                 contact,
                                                                 index,
                                                                 autoFocus = false,
                                                                 handleInputChangeEvent,
                                                                 handleInputChange,
                                                                 handleDelete,
                                                                 showAge = false}) => {

  const populated = contact?.initials && contact.startDate && contact.presentingProblem
    && contact.treatmentSetting && contact.supervisor.id && contact.numberOfSessions;

  const checkValid = (input: any) => input !== undefined && !!input;
  const checkInvalid = (input: any) => input !== undefined && !input;

  return (
    <Accordion.Item eventKey={"clinical-contact-" + index}>
      <Accordion.Header className={"border-0 h4 " + populated ? "bg-success": "bg-danger"} id={"clinical-contact-heading-" + index}>
        {index + 1} - {contact?.initials ?? "New client"}
        &nbsp;{populated ?
          <i className={"bi bi-check2-circle fs-5"}/>
        :
          <i className={"bi bi-x-circle text-warning"} title={"Entry incomplete"} />}
      </Accordion.Header>
      <Accordion.Body id={"clinical-contact-body-" + index} data-parent={"#clinicalContacts"}>
        <CardBody>
          <Row className="mb-2">
            <Col xs={6}>
              <Row>
                <Col xs={4}>
                  <FormGroup key={`contact[${index}].initials}`}>
                    <Form.Label className="h4">Client initials</Form.Label>
                    <Form.Control
                      name="initials"
                      value={contact?.initials || ""}
                      required
                      autoCapitalize={"characters"}
                      onChange={(e) => handleInputChange("initials", e.target.value.toUpperCase())}
                      autoFocus={autoFocus}
                      isValid={checkValid(contact?.initials)}
                      isInvalid={checkInvalid(contact?.initials)}
                    />
                  </FormGroup>
                </Col>
                {showAge &&
                  <Col>
                    <FormGroup key={`contact[${index}].age}`}>
                      <Form.Label className={"h4"}>Client age</Form.Label>
                      <Form.Control
                        name="age"
                        value={contact?.age || ""}
                        type="number"
                        max={18}
                        min={1}
                        required
                        onChange={(e) => handleInputChange("age", e.target.value.toUpperCase())}
                        isValid={checkValid(contact?.age)}
                        isInvalid={checkInvalid(contact?.age)}
                      />
                    </FormGroup>
                  </Col>
                }
                <Col>
                  <FormGroup key={`contact[${index}].startDate}`}>
                    <Form.Label className={"h4"}>Start date of EMDR</Form.Label>
                    <Form.Control
                      name="startDate"
                      value={contact?.startDate || ""}
                      type="date"
                      max={today}
                      required
                      onChange={handleInputChangeEvent}
                      isValid={checkValid(contact?.startDate)}
                      isInvalid={checkInvalid(contact?.startDate)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col xs={5}>
              <FormGroup key={`contact[${index}].presentingProblem}`}>
                <Form.Label className="h4">Presenting problem</Form.Label>
                <Form.Control
                  name="presentingProblem"
                  value={contact?.presentingProblem || ""}
                  onChange={handleInputChangeEvent}
                  required
                  isValid={checkValid(contact?.presentingProblem)}
                  isInvalid={checkInvalid(contact?.presentingProblem)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <FormGroup key={`contact[${index}].numberOfSessions}`}>
                <Form.Label className="h4">No. of sessions</Form.Label>
                <Form.Control
                  name="numberOfSessions"
                  value={contact?.numberOfSessions || ""}
                  onChange={handleInputChangeEvent}
                  type="number"
                  min={1}
                  required
                  isValid={contact?.numberOfSessions !== undefined && contact?.numberOfSessions > 0}
                  isInvalid={contact?.numberOfSessions !== undefined && contact?.numberOfSessions < 1}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup key={`contact[${index}].treatmentSetting}`}>
                <Form.Label className="h4">Setting where the treatment took place</Form.Label>
                <Form.Control
                  name="treatmentSetting"
                  value={contact?.treatmentSetting || ""}
                  onChange={handleInputChangeEvent}
                  required
                  isValid={checkValid(contact?.treatmentSetting)}
                  isInvalid={checkInvalid(contact?.treatmentSetting)}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup key={`contact[${index}].supervisor}`}>
                <Form.Label className="h4">Supervisor</Form.Label>
                <MemberSelect
                  value={contact?.supervisor}
                  name={"supervisor"}
                  onSelect={(value) => handleInputChange("supervisor", value)}
                  required
                  consultantsOnly={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className={"mt-2"}>
            <Col className={"offset-10"}>
              <Button className={"btn btn-outline-danger btn-light"} onClick={() => handleDelete()}><i className={"bi-trash"}></i> Delete client</Button>
            </Col>
          </Row>
        </CardBody>
      </Accordion.Body>
    </Accordion.Item>);
}

export default ClinicalContactEntry;