import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, FormGroup, Row } from "react-bootstrap";
import IFormStep from "interfaces/IFormStep";
import ISimpleNameValuePair from "interfaces/ISimpleNameValuePair";
import ApplicationRequirementControl from "../Components/ApplicationRequirementControl";
import _ from "lodash";

const YourExperience: React.FC<IFormStep> = ({formData, handleInputChange, requirements}) => {

  const [experiences, setExperiences] = React.useState<ISimpleNameValuePair[]>(formData.experiences || []);

  useEffect(() => {
    handleInputChange('experiences', experiences);
  }, [experiences]);

  const handleChange = (name: string, value: any) =>
    setExperiences((prevState) => _.unionBy([{name, value}], prevState,'name'));

  const merged = _.merge(_.keyBy(requirements.experience, "name"), _.keyBy(experiences, "name"));
  const formInputs = _.values(merged);

  return (
    <>
      <Row className="col-6">
        <h3 className="fw-bold mb-3">Your experience</h3>
        <h4>Tell us about your experience with EMDR.</h4>
      </Row>
      {formInputs.map(({value, ...requirement}) => {
        const { name, title, description } = requirement;
        return (
          <Row className="col-6 mb-4" key={name}>
            <FormGroup data-testid={`application-requirement-${name}`}>
              <Card className="bg-body-secondary border-0">
                <CardHeader className="border-0 h4">
                  <Card.Title className={"fw-bold h4"}>{title}</Card.Title>
                </CardHeader>
                {description &&
                <Card.Subtitle className={"text-muted border-0 px-3 mt-1"}>
                  {description}
                </Card.Subtitle>
                }
                <CardBody className={"mb-1"}>
                  <ApplicationRequirementControl
                    requirement={requirement}
                    name={name}
                    value={value}
                    handleChange={handleChange}
                  />
                </CardBody>
                {requirement.min && requirement.max ?
                  <Card.Footer className={"border-0"}>Please enter a number between <span className="fw-bold">{requirement.min}</span> and <span className="fw-bold">{requirement.max}</span>.</Card.Footer>
                :
                  requirement.min && <Card.Footer className={"border-0"}>Please enter a number greater than or equal to <span className="fw-bold">{requirement.min}</span>.</Card.Footer>
                }
              </Card>
            </FormGroup>
          </Row>);
      })}
    </>
  );
};

export default YourExperience;