import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AccreditationBlock from '../Blocks/AccreditationBlock';
import CPDBlock from '../Blocks/CPDBlock';
import MembershipBlock from '../Blocks/MembershipBlock';
import MembershipBlockCTA from '../Blocks/MembershipBlock/MembershipBlockCTA';
import { DocumentList } from '../Documents/DocumentList';
import { EventsContainer } from '../Events';
import Experience from './ExperienceBlock';
import UserProfile from './UserProfileBlock';
import Welcome from './WelcomeBlock';

interface IMemberDashboard {

}

const MemberDashboard: React.FC<IMemberDashboard> = ({

}) => {
    return (<>
        <Row className={"d-none d-md-flex"}>
            <Welcome />
        </Row>
        <Row>
            <Col md={6} lg={6} xl={4}>
                <UserProfile />
                <Experience />
                <DocumentList compact />
                {/* <UserLocations />*/}
            </Col>
            <Col md={6} lg={6} xl={8}>
                <Row>
                    <Col xl={6}>
                        <CPDBlock displayAction={true} />
                        <AccreditationBlock displayAction={true} />
                    </Col>
                    <Col xl={6}>
                        <MembershipBlock callToAction={<MembershipBlockCTA />} />
                    </Col>
                </Row>
                <EventsContainer compact />
            </Col>

        </Row>
    </>);
};

export default MemberDashboard;