import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_EVENTS } from '../graphql/profile/queries';
import { MemberEvent } from '../graphql/__generated__/graphql';

export const useEvents = () => {

    const { data, error, loading } = useQuery(GET_EVENTS);
    const [events, setEvents] = useState<MemberEvent[]>();
    const [total, setTotal] = useState(0);

    useEffect(() => {

        if (!loading && !error) {
            setEvents(data.events.events);
            setTotal(data.events.total);
        }

        if (error)
            console.log("Error retriving documents", error);
    }, [error, loading, data]);

    return {
        models: {
            loading, error, events, total
        },
        operations: {

        }
    };
}