import React, { useEffect, useRef } from 'react';
import { Accordion, Button, Col, Row } from "react-bootstrap";
import IFormStep from "interfaces/IFormStep";
import ClinicalContactEntry from "../Components/ClinicalContactEntry";
import IClinicalContact from "interfaces/IClinicalContact";
import IMember from "interfaces/IMember";
import { LoadingError } from "components/LoadingError";
import _ from "lodash";

const ClinicalContact: React.FC<IFormStep> = ({formData, requirements, handleInputChange}) => {

  const [clinicalContacts, setClinicalContacts] = React.useState<IClinicalContact[]>(formData.clinicalContacts || []);
  const [defaultActiveKey, setDefaultActiveKey] = React.useState<string>("clinical-contact-" + clinicalContacts.findIndex(contact => !(
      contact.initials && contact.startDate && contact.presentingProblem
      && contact.treatmentSetting && contact.supervisor && contact.numberOfSessions
  )));

  const accordionRef = useRef(null);

  useEffect(() => {
    handleInputChange('clinicalContacts', clinicalContacts);
  }, [clinicalContacts]);

  const handleClientUpdate = (name: string, value: string | IMember, index: number) => {
    setClinicalContacts((prevState) => (
      prevState.map((s, i) => {
        if (i === index) {
          return {
            ...s,
            [name]: value
          };
        }

        return s;
      }))
    );
  }

  const handleAddClient = () => {
    setClinicalContacts(prevState => {
      if (prevState.length) {
        const { supervisor, treatmentSetting } = prevState[prevState.length - 1];
        return prevState.concat([{
          supervisor,
          treatmentSetting,
        }])
      } else {
        return prevState.concat([{
          supervisor: {id: "", name: ""}
        }])
      }
    });

    setDefaultActiveKey("clinical-contact-" + (clinicalContacts.length));
  };

  const handleDeleteClient = (index: number) => {
    setClinicalContacts(prevState => {
      return _.filter(prevState, (_item, i) => i !== index);
    })
  }

  const clientRequirements = requirements.experience?.find(e => e.name === "clients-treated");
  if (!clientRequirements) return <LoadingError/>

  return (
    <>
      <Row>
        <h3 className="fw-bold mb-3">EMDR clinical contact activity</h3>
      </Row>
      <Row className={"mb-3"}>
        <h4>Add details for each client separately. A new record entry form will appear automatically when you complete
          a record.</h4>
        <h4>You'll need to enter at least {clientRequirements.min} {clientRequirements.max ? ` (max ${clientRequirements.max}) ` : ""}
          different cases to continue your application. The majority of records you enter should reflect all 8 phases.</h4>
      </Row>
      <Row className="mb-4">
        <Col xs={8}>
          <Accordion id="clinicalContacts" activeKey={defaultActiveKey} ref={accordionRef} onSelect={(eventKey) => setDefaultActiveKey(`${eventKey}`)}>
            {clinicalContacts.map((contact, index) => (
                <ClinicalContactEntry
                  key={`clinicalContact[${index}]`}
                  index={index}
                  contact={contact}
                  handleInputChangeEvent={(e) => handleClientUpdate(e.target.name, e.target.value, index)}
                  handleInputChange={(name, value) => handleClientUpdate(name, value, index)}
                  handleDelete={() => handleDeleteClient(index)}
                  autoFocus={!contact.initials}
                  showAge={requirements.isCA}
                />
            ))}
          </Accordion>
        </Col>
      </Row>
      <Row>
        <Col xs={5}>
          <Button className="btn-primary" onClick={handleAddClient}>Add client</Button>
        </Col>
      </Row>
    </>
  );
};

export default ClinicalContact;