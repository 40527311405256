import { Alert } from "react-bootstrap";
import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode | string
}

const Notice: React.FC<Props> = ({ children }) => {
  return (
    <Alert variant="warning">
      <p>
        <>
          <i className="bi bi-info-circle mx-2"></i>
          {children}
        </>
      </p>
    </Alert>
  )
}

export default Notice;