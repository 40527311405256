import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap";
import React from "react";
import ApplicationRequirementControl from "./ApplicationRequirementControl";
import ISimpleNameValuePair from "interfaces/ISimpleNameValuePair";
import { IAppRequirement } from "interfaces/IApplicationRequirements";
import Notice from "./Notice";

interface IApplicationsRequirementsBlockProps {
  title: string,
  description: string,
  requirements: IAppRequirement[],
  handleChange: (name: string, value: any, index: number) => void,
  selectOptions?: {id: string, name: string}[],
  applicationData: ISimpleNameValuePair[],
  alert?: string
}

const ApplicationRequirementsBlock: React.FC<IApplicationsRequirementsBlockProps> = ({
   title,
   description,
   requirements,
   handleChange,
   selectOptions,
   applicationData,
   alert}) => {

  return (
      <Card className={"bg-white border-0"}>
        <CardHeader className={"border-0 bg-transparent h4"}>
          <Card.Title className={"fw-bold"}>{title}</Card.Title>
        </CardHeader>
        {description &&
          <Card.Subtitle className={"text-muted border-0 px-3"}>
            {description}
          </Card.Subtitle>
        }
        <CardBody className={"text-black"}>
          {requirements.map((requirement, index) => (
            <Row key={`${requirement.name}`} className={"mb-2"}>
              <Col>
                <ApplicationRequirementControl
                  name={`${requirement.name}`}
                  value={applicationData.find(ad => ad.name === `${requirement.name}`)?.value || ""}
                  requirement={{...requirement}}
                  selectOptions={selectOptions}
                  handleChange={(name, value) => handleChange(name, value, index)}
                />
              </Col>
            </Row>
          ))}
          {alert &&
            <Row className={"mt-4"}>
              <Notice>
                {alert}
              </Notice>
            </Row>
          }
        </CardBody>
      </Card>
  );
}

export default ApplicationRequirementsBlock;