import React, { PropsWithChildren, ReactElement } from 'react';
import { Button, ButtonToolbar, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SaveProgressButton from "../../Accreditation/ApplicationForm/Components/SaveProgressButton";
import { FeedbackLevel } from "../../../interfaces/FeedbackLevel";

export interface IStepConfig {
  title: string;
  content?: React.FC<any>,
  onChangeStep?: (form: React.MutableRefObject<null>, callback: (success: boolean, msg?: string[]) => void) => void
  showNextButton?: boolean,
  showSaveProgressButton?: boolean,
}

interface FormStepperProps extends PropsWithChildren {
  stepNumber: number,
  formRef: React.MutableRefObject<null>,
  handleNextStep: (back?: boolean) => void,
  handleCancel: () => void,
  handleFeedback?: (level: FeedbackLevel, message?: string[]) => void,
  handleSubmit: (e: React.FormEvent) => void,
  submitted: boolean,
  submitting: boolean,
  allowSubmit: boolean,
  steps: IStepConfig[],
  renderStep?: (comp: React.FC<any>, stepNumber: number) => ReactElement,
  handleSaveProgress?: () => undefined | Promise<any>,
  handleSetStep?: (step: number) => void,
  submitText?: string,
  readOnly?: boolean,
  allowSkipStep?: boolean
}

const FormStepper: React.FC<FormStepperProps> = ({
                                                   stepNumber,
                                                   steps,
                                                   children,
                                                   formRef,
                                                   handleNextStep,
                                                   handleCancel,
                                                   handleSubmit,
                                                   handleFeedback,
                                                   handleSaveProgress,
                                                   handleSetStep,
                                                   submitted,
                                                   submitting,
                                                   allowSubmit,
                                                   renderStep,
                                                   submitText = 'Join Now',
                                                   readOnly = false,
                                                   allowSkipStep = false
                                                 }) => {

  const navigate = useNavigate();

  const stepTitles = steps.map(step => step.title);
  const totalSteps = steps.length;
  const { content: CurrentStep, showNextButton = true, showSaveProgressButton = true, onChangeStep = (_form, callback) => callback(true) } = steps[stepNumber - 1];

  const handleChangeStep = (nextStep: number) => {
    if (!allowSkipStep || !handleSetStep) return;
    if (!onChangeStep) handleSetStep(nextStep + 1);

    return onChangeStep(formRef, (success, message) => {
      if ((nextStep < stepNumber) || success) return handleSetStep(nextStep + 1);

      if (!success) {
        handleFeedback && handleFeedback("danger", message);
      }
    });
  }

  const getStepIcons = () => {
    const icons = [];
    for (let i = 0; i < totalSteps; i++) {
      icons.push(
        <div key={i}
             className="d-flex flex-column justify-content-between align-items-between text-center mx-3" title={allowSkipStep ? "Skip to " + stepTitles[i]  : ""}
             style={{"cursor": allowSkipStep ? "pointer" : "default"}}
             onClick={() => handleChangeStep(i)}>
          <div className="d-flex flex-column align-items-center" style={{ width: "140px" }}>
            <i
              className={`bi bi-${stepNumber >= (i + 2) ? "check" : (i + 1)}-circle-fill me-2 fs-5 text-${stepNumber >= (i + 1) ? "success" : "primary"} `}
              style={{ opacity: stepNumber >= (i + 1) ? 1 : 0.8 }}>
            </i>

            {i !== (totalSteps - 1) &&
              <div className={`bg-${stepNumber >= (i + 2) ? "success" : "primary"}`} style={{
                position: "absolute",
                marginTop: 12,
                marginRight: -165,
                width: "140px",
                height: "5px",
                borderRadius: "50px",
                opacity: stepNumber >= (i + 2) ? 1 : 0.8
              }}></div>
            }
          </div>

          <p style={stepNumber === (i + 1) ? { fontWeight: 'bold' } : undefined}><span>{stepTitles[i]}</span></p>
        </div>
      );
    }
    return icons;
  }

  const progress = (e: React.FormEvent, back?: boolean) => {
    scrollToTop();
    back ? stepNumber === 1 ? handleCancel() : handleNextStep(back)
      : allowSubmit ? handleSubmit(e) : handleNextStep();
  }

  const scrollToTop = () => {
    let c = formRef.current as any;
    if (c) c.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Row className="my-4 ">
        <Col className="d-flex justify-content-center align-items-center position-relative">
          {getStepIcons()}
        </Col>
      </Row>
      {showSaveProgressButton && !readOnly &&
        <SaveProgressButton onClick={handleSaveProgress} />
      }
      {renderStep && CurrentStep && renderStep(CurrentStep, stepNumber)}
      {children}
      <ButtonToolbar className="w-100 mt-4 d-flex justify-content-between">
        {submitted ? <Button className="my-4 mx-auto" size="lg" style={{ minWidth: "170px" }}
                             onClick={() => navigate("/dashboard")}>Sign In</Button> : <>

          <Button
            variant="outline-primary"
            onClick={(e) => stepNumber === 1 ? handleCancel() : progress(e, true)}
            disabled={submitted}
            style={{ borderRadius: 0 }}

          >
            <i className="bi bi-arrow-left pe-2"></i>
            {stepNumber === 1 ? "Cancel" : "Back"}
          </Button>

          {showNextButton && stepNumber < steps.length &&
            !allowSubmit &&
            <Button variant="info"
                    onClick={(e) => onChangeStep(formRef, (success) => success && progress(e))}
                    style={{ borderRadius: 0 }}
            >
              <>Next<i className="bi bi-arrow-right ps-2"></i></>
            </Button>}
          {allowSubmit && !submitting &&
            <Button onClick={(e) => progress(e)} style={{ borderRadius: 0 }}>
              <> {submitText}<i className="bi bi-check ps-2"></i></>
            </Button>}
          {allowSubmit && submitting &&
            <Button disabled style={{ borderRadius: 0 }}>
              <> {submitText}


                <Spinner style={{ marginLeft: "1em" }} animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>


              </>
            </Button>
          }

        </>}
      </ButtonToolbar>
    </>

  );
};

export default FormStepper;