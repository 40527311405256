import { MemberProfile } from "graphql/__generated__/graphql";
import { Col, Row } from "react-bootstrap";
import React from "react";

interface MemberDetailsProps {
  memberProfile: MemberProfile
}

const MemberDetailsBlock: React.FC<MemberDetailsProps> = (
  {
    memberProfile
  }) => {

  const {
    firstName,
    lastName,
    email,
    phone,
    qualifications,
    address,
    profession} = memberProfile;

  const renderList = (items: string[]) => {
    return (<ul className={"list-unstyled"}>
      {items.map(i => <li key={i}>{i}</li>)}
    </ul>);
  }

  return (
    <>
      <Row>
        <Col>
          <dl className={"row"}>
            <dt className={"col-sm-6"}>First name</dt>
            <dd>{firstName}</dd>
          </dl>
          <dl className={"col-sm-6"}>
            <dt className={"col-sm-6"}>Address</dt>
            <dd>
              {renderList([
                address?.addressLine1,
                address?.addressLine2,
                address?.city,
                address?.postcode,
                address?.country,
              ].filter(Boolean) as string[])}
            </dd>
          </dl>
        </Col>
        <Col>
          <dl className={"row"}>
            <dt className={"col-sm-6"}>Last name</dt>
            <dd>{lastName}</dd>
          </dl>
          <dl className={"row"}>
            <dt className={"col-sm-6"}>Telephone</dt>
            <dd>{phone}</dd>
          </dl>
          <dl className={"row"}>
            <dt className={"col-sm-6"}>Email</dt>
            <dd>{email}</dd>
          </dl>
        </Col>
      </Row>
      <Row>
        <Col>
          <dl className={"row"}>
            <dt className={"col-sm-6"}>Qualifications</dt>
            <dd>
              {qualifications && renderList(qualifications?.split(","))}
            </dd>
          </dl>
          <dl className={"row"}>
            <dt className={"col-sm-6"}>Core profession</dt>
            {profession && renderList(profession?.split(","))}
          </dl>
        </Col>
      </Row>
    </>
  );
}

export default MemberDetailsBlock;