import React, {useEffect, useRef} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import FormStepper from "../../Form/FormStepper/FormStepper";
import {useAccreditationApplicationFormComponent} from "./Hooks/useAccreditiationApplicationFormComponent";
import IFormStep from "interfaces/IFormStep";
import {useLocation, useNavigate} from "react-router-dom";
import {LoadingSpinner} from "../../LoadingSpinner";
import {FeedbackLevel} from "../../../interfaces/FeedbackLevel";
import ToastNotification, {ToastMessage} from "../../Form/ToastNotification";

const ApplicationForm: React.FC = () => {

  const formRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const checkLocalStorage = () => {
    const backup = localStorage.getItem("accreditationApplicationBackup");

    if (!backup) return location.state?.application;
     try {
       return JSON.parse(backup);
     } catch (e) {
       console.warn("Invalid application backup detected and removed");
       return;
     }
  };

  const application = checkLocalStorage();

  if (!application) {
    navigate("/accreditation");
  }
  const { models, operations } = useAccreditationApplicationFormComponent(application);

  const [toastMessage, setToastMessage] = React.useState<ToastMessage>();

  const { formData, updating, currentStep, requirements, validated, operationResult} = models;
  const { handleNextStep, handleInputChangeEvent, handleInputChange, handleSaveProgress, handleSetStep, handleSubmitApplication, handleApplicationUpdate } = operations;

  const handleSubmit = async () => {
    return handleSubmitApplication().then(() => {
      navigate("/accreditation?formSubmitResult=application");
      return this;
    }).catch(() => {
      handleFormFeedback("danger", "Something went wrong when submitting the application. Please try again later");
    });
  }

  const handleFormFeedback = (level: FeedbackLevel, message?: string[] | string) => {
    setToastMessage({
      message,
      level,
      id: new Date().getTime()
    });
  }

  useEffect(() => {
    operationResult?.message && handleFormFeedback(operationResult?.success ? "info" : "danger", operationResult?.message);
  }, [operationResult]);

  const renderStep = (Step: React.FC<IFormStep>) =>
    <Step
      formData={formData}
      models={models}
      handleInputChangeEvent={handleInputChangeEvent}
      handleFeedback={handleFormFeedback}
      handleNext={handleNextStep}
      handleInputChange={handleInputChange}
      handleSubmitApplication={handleSubmit}
      handleApplicationUpdate={handleApplicationUpdate}
      requirements={requirements}
    />

  return (
    <>
      <h2 className="fs-1 fw-bold mb-5">Apply for {application.accreditationType.name} accreditation</h2>
      <Row>
        <Col>
          <Form ref={formRef} validated={!validated}>
            <FormStepper
              stepNumber={currentStep}
              renderStep={renderStep}
              steps={models.stepsConfig}
              formRef={formRef}
              handleFeedback={handleFormFeedback}
              handleSetStep={handleSetStep}
              handleNextStep={handleNextStep}
              handleCancel={() => {navigate("/accreditation")}}
              handleSubmit={() => {console.log('submit')}}
              handleSaveProgress={handleSaveProgress}
              submitted={false}
              submitting={false}
              allowSubmit={false}
              allowSkipStep={!!models.application.accreditationApplicationId}
              submitText={"Submit and pay"}>
            </FormStepper>
          </Form>
        </Col>
      </Row>
      {updating && <Row><LoadingSpinner/></Row>}
      {toastMessage && <ToastNotification {...toastMessage} />}
    </>
  )
}

export default ApplicationForm;