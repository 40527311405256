import React from 'react';
import IAccreditationApplicationForm from "interfaces/IAccreditationApplicationForm";
import { MemberProfile } from "graphql/__generated__/graphql";
import MemberDetailsBlock from "./MemberDetailsBlock";
import IApplicationRequirements, { IAppRequirement } from "interfaces/IApplicationRequirements";
import _ from "lodash";
import { useDocuments } from "hooks/useDocuments";
import ClinicalContactReviewEntry from "../../ClinicalContactReviewEntry";

interface IApplicationReviewBlockProps {
  formData: IAccreditationApplicationForm,
  memberProfile?: MemberProfile,
  requirements: IApplicationRequirements
}

const ApplicationReviewBlock: React.FC<IApplicationReviewBlockProps> = (
  {
    formData,
    requirements,
    memberProfile,
  }) => {

  const { operations } = useDocuments();

  const renderList = (items: any[]) => {
    return (<ul className={"list-unstyled"}>
      {items?.map((item, index: any) => <li key={index}>{item}</li>)}
    </ul>);
  }

  const toNiceOutput = (value: any) => {
    switch (typeof value) {
      case "undefined": return "unknown";
      case "boolean": return value ? "Yes" : "No";
      default: return value;
    }
  }

  const mergedExperience = _.merge(_.keyBy(requirements.experience, "name"), _.keyBy(formData.experiences, "name"));
  const experiences = _.values(mergedExperience).map(exp => `${toNiceOutput(exp.value)} - ${exp.title}`);

  const flattenedDocs: IAppRequirement[] = _.flatMap(requirements.documents, "requirements");
  const mergedDocuments = _.values(_.merge(_.keyBy(flattenedDocs, "name"), _.keyBy(formData.documents, "name")));

  return (
    <>
      {memberProfile && <MemberDetailsBlock memberProfile={memberProfile}/>}
      <dl className={"row"}>
        <dt className={"col-sm-6"}>Your experience</dt>
        <dd>{renderList(experiences)}</dd>
      </dl>
      <dl className={"row"}>
        <dt className={"col-sm-6"}>Clinical contact records</dt>
        {(formData.clinicalContacts?.map((cc, i) => (
          <ClinicalContactReviewEntry key={i} cc={cc}/>
        )))}
      </dl>
      <dl className={"row"}>
        <dt className={"col-sm-6"}>Supervisors</dt>
        <dd>{renderList(formData.supervisors?.map(s => s.name + ": " + toNiceOutput(s.startDate) + " - " + (s.endDate ?? (s.ongoing ? "present" : "unknown"))))}</dd>
      </dl>
      <dl className={"row"}>
        <dt className={"col-sm-6"}>Documents</dt>
        <dd>{renderList(mergedDocuments.filter(d => !!d.value).map(d =>
          <a onClick={(e) => {e.preventDefault(); operations.downloadDocument(d.value as string)}} href="#" title={`Download + ${d.title}`}>{d.title}</a>
          ))}
        </dd>
      </dl>
    </>
  );
};

export default ApplicationReviewBlock;