import IReviewFormProps from "./IReviewFormProps";
import React, {useRef} from "react";
import {Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import FormStepper from "../../Form/FormStepper/FormStepper";
import {LoadingSpinner} from "../../LoadingSpinner";
import {useSupervisorEvaluationFormComponent} from "./useSupervisorEvaluationFormComponent";
import IEvaluationFormStep from "interfaces/IEvaluationFormStep";
import {FeedbackLevel} from "../../../interfaces/FeedbackLevel";
import ToastNotification, {ToastMessage} from "../../Form/ToastNotification";

const PrimarySupervisorReviewForm: React.FC<IReviewFormProps> = ({reviewSummary, readOnly}) => {

  const formRef = useRef(null);
  const navigate = useNavigate();

  const { models, operations } = useSupervisorEvaluationFormComponent(reviewSummary);
  const { formData, updating, currentStep, requirements} = models;
  const { handleNextStep, handleInputChangeEvent, handleInputChange, handleSaveProgress, handleSetStep } = operations;

  const [toastMessage, setToastMessage] = React.useState<ToastMessage>();

  if (!requirements?.evaluation || models.loading) return <LoadingSpinner />;

  const openApplicationInNewTab = () => {
    localStorage.setItem("application", JSON.stringify(models.applicationReview.accreditationApplication));
    window.open("/accreditation/view", "_blank", "noreferrer");
  }

  const handleFormFeedback = (level: FeedbackLevel, message?: string[] | string) => {
    setToastMessage({
      message,
      level,
      id: new Date().getTime()
    });
  }

  const renderStep = (Step: React.FC<IEvaluationFormStep>, stepNumber: number) =>
    <Step
      formData={formData}
      formRef={formRef}
      models={models}
      handleFeedback={handleFormFeedback}
      handleInputChangeEvent={handleInputChangeEvent}
      handleNext={handleNextStep}
      handleInputChange={handleInputChange}
      requirementsGroup={requirements.evaluation[stepNumber - 1]}
      readOnly={readOnly}
    />

  const stepsConfig = models.stepsConfig.slice(0, readOnly ? models.stepsConfig.length - 1 : models.stepsConfig.length);

  return (
    <>
      <h2 className="fs-1 fw-bold mb-5">Supervisor evaluation for {reviewSummary.applicant.name}</h2>
      <h4>View {reviewSummary.applicant.name}'s application
        &nbsp;<a
          href={"/accreditation/view"}
          target={"_blank"}
          rel={"noreferrer"}
          onClick={(e) => { e.preventDefault(); openApplicationInNewTab()}}>
          here
        </a>
        &nbsp;(opens in a new tab)
      </h4>
      <Row>
        <Form noValidate ref={formRef} onSubmit={(event) => operations.handleSubmit()} action={"/accreditation"}>
          <FormStepper
            stepNumber={currentStep}
            renderStep={renderStep}
            steps={stepsConfig}
            formRef={formRef}
            allowSkipStep={true}
            handleFeedback={handleFormFeedback}
            handleSetStep={handleSetStep}
            handleNextStep={handleNextStep}
            handleCancel={() => {navigate("/accreditation")}}
            handleSubmit={() => {console.log('submit')}}
            handleSaveProgress={handleSaveProgress}
            submitted={false}
            submitting={false}
            allowSubmit={false}
            readOnly={readOnly}
            submitText={"Submit and pay"}>
          </FormStepper>
          <Form.Control name={"formSubmitResult"} value="evaluation" type={"hidden"}/>
        </Form>
      </Row>
      {updating && <Row><LoadingSpinner/></Row>}
      {toastMessage && <ToastNotification {...toastMessage} />}
    </>
  )
}

export default PrimarySupervisorReviewForm;