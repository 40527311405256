import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ApplicationsBlock from "./ApplicationsBlock";
import ReviewsBlock from "./ReviewsBlock";
import FormResultModal from "./FormResultModal";
import { useLocation } from "react-router-dom";

export const AccreditationPage: React.FC = () => {

    const location = useLocation();
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));

    return (
        <>
            {searchParams && <FormResultModal {...searchParams}/>}
            <Row>
                <h2 className="fs-1 fw-bold mb-5">Apply for accreditation</h2>
            </Row>
            <Row>
                <Col md={6}>
                    <ApplicationsBlock />
                </Col>
                <Col md={2}>

                </Col>
                <Col md={4}>
                    <ReviewsBlock />
                </Col>
            </Row>
        </>
    );
};
